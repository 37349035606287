import { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import {
  AutocompleteValue,
  Box,
  SxProps,
  Typography,
  Theme,
  Tooltip,
  ListItem,
  Stack,
} from '@mui/material';
import {
  AutocompleteFieldNonFormik,
  AutocompleteFieldProps,
} from '@watershed/ui-core/components/Form/AutocompleteField';
import { Autocomplete } from '@watershed/ui-core/components/Form/Autocomplete';
import { gql } from 'graphql-tag';
import {
  useTypeaheadCompanyQuery,
  useGetCompanyQuery,
} from '../generated/urql';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import {
  useGridApiContext,
  GridRenderEditCellParams,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import {
  GQCompanyAutocompleteResultFragment,
  GQCompanyAutocompleteResultWithSourceFragment,
} from '@watershed/shared-universal/generated/graphql';
import { countries } from '@watershed/shared-universal/countryConstants';
import { getIndustryLabelFromNaicsCode } from '@watershed/shared-universal/industryCodes/industryCodeUtils';
import { mixinSx } from '@watershed/style/styleUtils';
import { longTextContainerSx } from '@watershed/ui-core/components/DataGrid/DataGridTextWithTooltipCell';
import uniqBy from 'lodash/uniqBy';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import BoxSumIcon from '@watershed/icons/components/BoxSum';
import isNullish from '@watershed/shared-util/isNullish';
import useDebounce from '@watershed/ui-core/hooks/useDebounce';

const DEBOUNCE_DELAY = 500;

gql`
  fragment CompanyAutocompleteResult on Company {
    id
    name
    countryAlpha2
    naicsCode
    sAndP {
      id
      sAndPId
    }
  }
  fragment CompanyAutocompleteResultWithSource on Company {
    id
    name
    countryAlpha2
    naicsCode
    source
    sAndP {
      id
      sAndPId 
    }
  }
  query TypeaheadCompany(
    $search: String!
    $allowMatchById: Boolean!
    $searchDemo: Boolean!
  ) {
    companyFuzzyMatch(
      q: $search
      allowMatchById: $allowMatchById
      searchDemo: $searchDemo
    ) {
      ...CompanyAutocompleteResultWithSource
    }
  }
`;

gql`
  query getCompany($id: ID) {
    companyForDashboard(id: $id) {
      ...CompanyAutocompleteResultWithSource
    }
  }
`;

export function CompanyAutocompleteNewCompanyOption(
  props: HTMLAttributes<HTMLLIElement>,
  option: GQCompanyAutocompleteResultFragment
) {
  return (
    <ListItem
      {...props}
      key={option.id}
      sx={mixinSx(props.style, {
        position: 'sticky',
        bottom: 0,
        background: (theme) => theme.palette.background.paper,
        borderTop: '2px solid',
        borderColor: 'divider',
      })}
      data-test={TestIds.CompanyMatchingTableNoMatchFoundOption}
    >
      <Typography variant="h4">{option.name}</Typography>
    </ListItem>
  );
}

const cantFindVendorMatchId = 'CAN_NOT_FIND_VENDOR';
export function cantFindVendorMatchOption(
  message: string
): GQCompanyAutocompleteResultFragment {
  return {
    id: cantFindVendorMatchId,
    name: message,
    countryAlpha2: null,
    naicsCode: null,
    sAndP: null,
  };
}

export default function CompanyAutocompleteNonFormik({
  id,
  required,
  value = null,
  label = <Trans context="A company as in legal entity">Company</Trans>,
  freeSolo = false,
  onChange,
  optionsIdBlocklist = [],
  showCompanyId = false,
  sx,
  additionalOptions = [],
  watershedSuggestedOptions = [],
  renderAdditionalOption,
  slotProps,
  defaultSearchTerm,
  startAdornment,
  allowMatchById = false,
  searchDemo = false,
  groupBy,
  companiesWithSupplierSpecificEf,
}: {
  id: string;
  value?: GQCompanyAutocompleteResultFragment | null;
  required?: boolean;
  label?: ReactNode;
  freeSolo?: boolean;
  onChange: (
    value: GQCompanyAutocompleteResultFragment | null | string
  ) => void;
  optionsIdBlocklist?: Array<string>;
  showCompanyId?: boolean;
  sx?: SxProps<Theme>;
  watershedSuggestedOptions?: Array<GQCompanyAutocompleteResultFragment>;
  additionalOptions?: Array<GQCompanyAutocompleteResultFragment>;
  renderAdditionalOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: GQCompanyAutocompleteResultFragment
  ) => ReactNode;
  slotProps?: AutocompleteFieldProps<
    GQCompanyAutocompleteResultFragment,
    false,
    false,
    boolean
  >['slotProps'];
  defaultSearchTerm?: string;
  startAdornment?: ReactNode;
  allowMatchById?: boolean;
  searchDemo?: boolean;
  groupBy?: (option: GQCompanyAutocompleteResultFragment) => string;
  companiesWithSupplierSpecificEf?: Array<string>;
}) {
  const [valueHasChanged, setValueHasChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value?.name ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const [result] = useTypeaheadCompanyQuery({
    variables: {
      search:
        debouncedSearchTerm === '' && isNotNullish(defaultSearchTerm)
          ? defaultSearchTerm
          : debouncedSearchTerm,
      allowMatchById,
      searchDemo,
    },
    pause:
      !valueHasChanged ||
      (isNullish(defaultSearchTerm) && debouncedSearchTerm === ''),
  });
  const additionalOptionIds = additionalOptions.map(({ id }) => id);

  const hasSelectedAResult =
    value &&
    (additionalOptionIds.includes(value.id) ||
      result.data?.companyFuzzyMatch.map(({ id }) => id).includes(value.id) ||
      watershedSuggestedOptions.map(({ id }) => id).includes(value.id));
  let baseOptions: Array<GQCompanyAutocompleteResultFragment> = [
    hasSelectedAResult ? null : value,
    ...watershedSuggestedOptions,
    ...(result.data?.companyFuzzyMatch.filter(
      ({ id }) =>
        !optionsIdBlocklist.includes(id) &&
        !watershedSuggestedOptions.map(({ id }) => id).includes(id)
    ) ?? []),
  ].filter(isNotNullish);

  // Add additional options
  baseOptions =
    isNullish(defaultSearchTerm) && debouncedSearchTerm === ''
      ? baseOptions
      : baseOptions.concat(additionalOptions ?? []);

  const options: ReadonlyArray<GQCompanyAutocompleteResultFragment> =
    baseOptions;

  return (
    <AutocompleteFieldNonFormik<
      GQCompanyAutocompleteResultFragment,
      false,
      false,
      boolean
    >
      id={id}
      data-test="field-CompanyAutocomplete"
      label={label === '' ? undefined : label}
      placeholder={t({
        message: 'Select a company',
        context: 'Placeholder in a dropdown to select a company (legal entity)',
      })}
      options={options}
      inputValue={searchTerm}
      filterOptions={(options) => options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value ?? null}
      InputProps={{
        startAdornment,
      }}
      sx={mixinSx(sx, {
        '& > li + li': {
          borderTop: 1,
          borderColor: 'divider',
        },
      })}
      renderOption={(props, option) => {
        const country = countries.find(
          (c) => c['alpha-2'] === option.countryAlpha2
        )?.name;
        const industry = option.naicsCode
          ? getIndustryLabelFromNaicsCode(option.naicsCode)
          : null;

        const sAndPId = option.sAndP?.sAndPId;

        if (renderAdditionalOption && additionalOptionIds.includes(option.id)) {
          return renderAdditionalOption(props, option);
        }

        const sAndPIdStr = sAndPId ? `S&P: ${sAndPId}` : '';
        const companyMetadata = [industry, country]
          .filter(isNotNullish)
          .join(' • ');
        return (
          <li {...props} key={typeof option === 'string' ? option : option.id}>
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              gap={1}
              justifyContent="space-between"
            >
              <Box width="100%" sx={longTextContainerSx}>
                <Tooltip
                  title={typeof option === 'string' ? option : option.name}
                  disableInteractive
                  placement="left"
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={longTextContainerSx}>
                      {typeof option === 'string' ? option : option.name}
                    </Typography>
                    <Typography variant="body2">{sAndPIdStr}</Typography>
                  </Stack>
                </Tooltip>
                {showCompanyId &&
                  typeof option !== 'string' &&
                  `(${option.id})`}

                {!industry && !country ? (
                  <Typography variant="body2">—</Typography>
                ) : (
                  <Tooltip
                    // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                    title={companyMetadata}
                    disableInteractive
                    placement="left"
                  >
                    <Typography variant="body2" sx={longTextContainerSx}>
                      {companyMetadata}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
              {companiesWithSupplierSpecificEf &&
                companiesWithSupplierSpecificEf.includes(option.id) && (
                  <Tooltip
                    title={
                      <Trans>
                        Supplier-specific EF available in Watershed database{' '}
                      </Trans>
                    }
                    disableInteractive
                    placement="right"
                  >
                    <div>
                      <BoxSumIcon size={16} color="grey50" />
                    </div>
                  </Tooltip>
                )}
            </Stack>
          </li>
        );
      }}
      onInputChange={(_event, value) => {
        setSearchTerm(value);
        setValueHasChanged(true);
      }}
      onChange={(_event, value) => {
        onChange(value);
      }}
      blurOnSelect
      required={required}
      freeSolo={freeSolo}
      loading={result.fetching}
      clearOnEscape
      slotProps={slotProps}
      noOptionsText="Start typing to search for a company"
      // Prevents the autocomplete from closing when user presses space
      onKeyDown={(event) => event.stopPropagation()}
      groupBy={groupBy}
    />
  );
}

// This is the same as CompanyAutocompleteNonFormik, but sets value as company id, filters FinancePortCoUnreview companies, and returns distinct results
export function CompanyIdAutocompleteNonFormik({
  id,
  required,
  value = null,
  label = <Trans context="A company as in legal entity">Company</Trans>,
  freeSolo = false,
  onChange,
  optionsIdBlocklist = [],
  showCompanyId = false,
  sx,
  additionalOptions = [],
  renderAdditionalOption,
  slotProps,
  defaultSearchTerm,
  startAdornment,
  allowMatchById = false,
  inline,
  disabled,
  renderLabel,
  searchDemo = false,
}: {
  id: string;
  value?: string | null;
  required?: boolean;
  label?: ReactNode;
  freeSolo?: boolean;
  onChange: (value: string) => void;
  optionsIdBlocklist?: Array<string>;
  showCompanyId?: boolean;
  sx?: SxProps<Theme>;
  additionalOptions?: Array<GQCompanyAutocompleteResultWithSourceFragment>;
  renderAdditionalOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: GQCompanyAutocompleteResultWithSourceFragment
  ) => ReactNode;
  slotProps?: AutocompleteFieldProps<
    GQCompanyAutocompleteResultWithSourceFragment,
    false,
    false,
    boolean
  >['slotProps'];
  defaultSearchTerm?: string;
  startAdornment?: ReactNode;
  allowMatchById?: boolean;
  inline?: boolean;
  disabled?: boolean;
  renderLabel?: (labelNode: ReactNode) => ReactNode;
  searchDemo?: boolean;
}) {
  const [valueHasChanged, setValueHasChanged] = useState(false);
  const [companyResult] = useGetCompanyQuery({ variables: { id: value } });
  const [searchTerm, setSearchTerm] = useState(
    companyResult?.data?.companyForDashboard?.name ?? ''
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [typeAheadResult] = useTypeaheadCompanyQuery({
    variables: {
      search:
        debouncedSearchTerm === '' && isNotNullish(defaultSearchTerm)
          ? defaultSearchTerm
          : debouncedSearchTerm,
      allowMatchById,
      searchDemo,
    },
    pause:
      !valueHasChanged ||
      (isNullish(defaultSearchTerm) && debouncedSearchTerm === ''),
  });
  const additionalOptionIds = additionalOptions.map(({ id }) => id);

  const hasSelectedAResult =
    value &&
    (additionalOptionIds.includes(value) ||
      typeAheadResult.data?.companyFuzzyMatch
        .map(({ id }) => id)
        .includes(value));

  const companyOptions: ReadonlyArray<GQCompanyAutocompleteResultWithSourceFragment> =
    uniqBy(
      [
        ...(typeAheadResult.data?.companyFuzzyMatch.filter(
          ({ id }) => !optionsIdBlocklist.includes(id)
        ) ?? []),
        ...additionalOptions,
      ].filter(isNotNullish),
      (c) => [c.name, c.source, c.naicsCode, c.countryAlpha2].join()
    );
  const options: ReadonlyArray<GQCompanyAutocompleteResultWithSourceFragment> =
    [
      hasSelectedAResult ? null : companyOptions.find((c) => c.id === value),
      ...companyOptions,
    ].filter(isNotNullish);

  const [companyValue, setCompanyValue] = useState<
    GQCompanyAutocompleteResultWithSourceFragment | string | null
  >(companyResult?.data?.companyForDashboard ?? null);
  useEffect(() => {
    setCompanyValue(companyResult?.data?.companyForDashboard ?? null);
  }, [companyResult]);

  return (
    <AutocompleteFieldNonFormik<
      GQCompanyAutocompleteResultWithSourceFragment,
      false,
      false,
      boolean
    >
      id={id}
      data-test="field-CompanyAutocomplete"
      label={label === '' ? undefined : label}
      placeholder={t({
        message: 'Select a company',
        context: 'Placeholder in a dropdown to select a company (legal entity)',
      })}
      options={options}
      inputValue={searchTerm}
      filterOptions={(options) => options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={companyValue}
      InputProps={{
        startAdornment,
      }}
      sx={mixinSx(sx, {
        '& > li + li': {
          borderTop: 1,
          borderColor: 'divider',
        },
      })}
      renderOption={(props, option) => {
        const country = countries.find(
          (c) => c['alpha-2'] === option.countryAlpha2
        )?.name;
        const industry = option.naicsCode
          ? getIndustryLabelFromNaicsCode(option.naicsCode)
          : null;
        if (renderAdditionalOption && additionalOptionIds.includes(option.id)) {
          return renderAdditionalOption(props, option);
        }
        const companyMetadata = [industry, country]
          .filter(isNotNullish)
          .join(' • ');

        const sAndPId = option.sAndP?.sAndPId;
        const sAndPIdStr = sAndPId ? `S&P: ${sAndPId}` : '';

        return (
          <li {...props} key={typeof option === 'string' ? option : option.id}>
            <Box sx={longTextContainerSx} width="100%">
              <Tooltip
                title={typeof option === 'string' ? option : option.name}
                disableInteractive
                placement="left"
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={longTextContainerSx}>
                    {typeof option === 'string' ? option : option.name}
                  </Typography>
                  <Typography variant="body2">{sAndPIdStr}</Typography>
                </Stack>
              </Tooltip>
              {showCompanyId && typeof option !== 'string' && `(${option.id})`}
              {!industry && !country ? (
                <Typography variant="body2">—</Typography>
              ) : (
                <Tooltip
                  // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                  title={companyMetadata}
                  disableInteractive
                  placement="left"
                >
                  <Typography variant="body2" sx={longTextContainerSx}>
                    {companyMetadata}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </li>
        );
      }}
      onInputChange={(_event, value) => {
        setSearchTerm(value);
        setValueHasChanged(true);
      }}
      onChange={(_event, value) => {
        onChange(value ? (typeof value === 'string' ? value : value.id) : '');
        setCompanyValue(
          value
            ? typeof value === 'string'
              ? value
              : (options.find((o) => o.id === value.id) ?? null)
            : null
        );
      }}
      blurOnSelect={true}
      required={required}
      freeSolo={freeSolo}
      loading={companyResult.fetching || typeAheadResult.fetching}
      clearOnEscape
      slotProps={slotProps}
      inline={inline}
      disabled={disabled}
      renderLabel={renderLabel}
    />
  );
}

// For use with MUI Datagrid
export function CompanyAutocompleteEditCell({
  id,
  field,
  value,
  freeSolo = false,
  allowMatchById = false,
  searchDemo = false,
}: GridRenderEditCellParams & {
  value?: GQCompanyAutocompleteResultWithSourceFragment | null;
  freeSolo: boolean;
  allowMatchById?: boolean;
  searchDemo?: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState(value?.name ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const [result] = useTypeaheadCompanyQuery({
    variables: {
      search: debouncedSearchTerm,
      allowMatchById,
      searchDemo,
    },
  });
  const hasSelectedAResult =
    value &&
    result.data?.companyFuzzyMatch.map(({ name }) => name).includes(value);

  const options: ReadonlyArray<GQCompanyAutocompleteResultWithSourceFragment> =
    [
      ...(result.data?.companyFuzzyMatch ?? []),
      hasSelectedAResult ? null : value,
    ].filter(isNotNullish);

  const apiRef = useGridApiContext();
  const handleValueChange = async (
    _: any,
    newValue: AutocompleteValue<
      GQCompanyAutocompleteResultWithSourceFragment,
      false,
      true,
      boolean
    >
  ) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <Autocomplete<
      GQCompanyAutocompleteResultWithSourceFragment,
      false,
      true,
      boolean
    >
      placeholder={t({
        message: 'Select a company',
        context: 'Placeholder in a dropdown to select a company (legal entity)',
      })}
      options={options}
      inputValue={searchTerm}
      freeSolo={freeSolo}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      value={value}
      onInputChange={(_event, value) => setSearchTerm(value)}
      onChange={handleValueChange}
      loading={result.fetching}
    />
  );
}
