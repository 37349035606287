import { useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import gql from 'graphql-tag';
import { useRouter } from 'next/router';
import { Trans, useLingui } from '@lingui/react/macro';

import DialogForm from '@watershed/ui-core/components/DialogForm';
import { useCreateSupportCaseMutation } from '@watershed/shared-frontend/generated/urql';
import { routeForSupportCase } from '@watershed/shared-universal/dashboardRoutes';
import {
  GQCreateSupportCaseInput,
  GQSupportCasePriority,
  GQSupportCaseIssueType,
} from '@watershed/shared-universal/generated/graphql';
import TextField, {
  TextFieldMultiline,
} from '@watershed/ui-core/components/Form/TextField';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import SupportCasePriorityIcon from './SupportCasePriorityIcon';
import Button from '@watershed/ui-core/components/Button';
import {
  SalesforceCaseIssueType,
  getIssueTypeLabel,
  getPriorityLabel,
} from '@watershed/shared-universal/entFound/supportUtils';
import { getPriorityDescription } from '@watershed/shared-universal/entFound/supportUtils';
import ErrorIcon from '@watershed/icons/components/Error';
import Callout from '@watershed/shared-frontend/components/Callout';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import { createDialogHook } from '@watershed/ui-core/hooks/useDialog';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

gql`
mutation CreateSupportCase($input: CreateSupportCaseInput!, $after: String, $before: String, $first: Int, $last: Int) {
  createSupportCase(input:$input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
`;

const MAX_SUPPORT_CASE_DESCRIPTION_LENGTH = 32000;

export type SupportCaseDialogProps = {
  onClose: () => void;
  issueType: GQSupportCaseIssueType;
  objectId?: string | null;
};

export default function NewSupportCaseDialog({
  onClose,
  objectId,
  issueType,
}: SupportCaseDialogProps) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [{ fetching: isSubmitting }, executeSubmit] =
    useCreateSupportCaseMutation();
  const router = useRouter();
  const theme = useTheme();
  const { t } = useLingui();
  const [selectedPriority, setSelectedPriority] =
    useState<GQSupportCasePriority | null>(null);

  return (
    <Formik<GQCreateSupportCaseInput>
      initialValues={{
        subject: '',
        description: '',
        priority: GQSupportCasePriority.Low,
        issueType,
        objectId,
      }}
      onSubmit={async (input) => {
        if (errorMsg) {
          setErrorMsg(null);
        }
        const result = await executeSubmit({
          input,
          after: null,
          before: null,
          first: 100,
          last: null,
        });
        if (result.data?.createSupportCase?.supportCase) {
          void router.push(
            routeForSupportCase(result.data.createSupportCase.supportCase.id, {
              conf: true,
            })
          );
        } else {
          if (result.error) {
            // TODO: Let's figure out how to safely extract only user-facing messages.
            // const msg =
            //   result.error.message ||
            //   result.error.networkError?.message ||
            //   result.error.graphQLErrors?.[0].message;
            setErrorMsg(
              'There was an error submitting your support case. Watershed has been notified.'
            );
          }
        }
        onClose();
      }}
    >
      {(form) => (
        <DialogForm
          onClose={() => {
            onClose();
            // Give yourself a breath for the animation
            setTimeout(() => {
              form.resetForm();
              setErrorMsg(null);
            }, 300);
          }}
          maxWidth="sm"
          header={{
            title: (
              <Trans context="form header copy for creating support cases">
                Help
              </Trans>
            ),
          }}
          sx={{
            // Dialogs play me close my butter plays toast
            '& .MuiDialogContent-root': { rowGap: 2 },
          }}
          isSubmitting={isSubmitting}
          submit={
            <Button
              color="primary"
              type="submit"
              disabled={
                isSubmitting ||
                !form.values.subject ||
                form.values.subject === '' ||
                !form.values.description ||
                form.values.description === '' ||
                !form.values.issueType
              }
              data-test={TestIds.SupportCaseSubmitButton}
            >
              <Trans context="Button copy to submit a support case">
                Submit
              </Trans>
            </Button>
          }
        >
          <Stack
            sx={{ borderBottom: `1px dashed ${theme.palette.divider}`, pb: 2 }}
          >
            <Typography variant="h4">
              <Trans context="Section header for a support case submission form">
                What seems to be the issue?
              </Trans>
            </Typography>
            <Typography variant="body2">
              <Trans>
                If you've found a bug or something isn't working as expected,
                please tell us the steps you took so we can reproduce the issue
                and help fix it.
              </Trans>
            </Typography>
          </Stack>

          {errorMsg && (
            <Callout
              title={
                <Trans>There was an error submitting your support case.</Trans>
              }
              variant="error"
              IconComponent={ErrorIcon}
              onDismiss={() => setErrorMsg(null)}
              description={errorMsg}
            />
          )}

          <TextField
            id="subject"
            required
            sx={{ gap: 1 }}
            label={
              <Trans context="Form label for support case subject">
                Subject
              </Trans>
            }
            data-test={TestIds.SupportCaseSubjectInput}
          />
          <SelectField
            id="issueType"
            required
            sx={{ gap: 1 }}
            label={
              <Trans context="Form label for support case feature area">
                Product area
              </Trans>
            }
            data-test={TestIds.SupportCaseProductAreaSelect}
          >
            {(
              Object.keys(SalesforceCaseIssueType) as Array<
                keyof typeof SalesforceCaseIssueType
              >
            ).map((value) => (
              <MenuItem key={value} value={value}>
                {getIssueTypeLabel(value)}
              </MenuItem>
            ))}
          </SelectField>
          <SelectField
            id="priority"
            required
            sx={{
              gap: 1,
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                maxHeight: 24,
              },
              '&& .MuiListItemIcon-root': {
                minWidth: 'fit-content',
                pr: 1,
              },
            }}
            label={
              <Trans context="Form label for support case priority">
                Priority
              </Trans>
            }
            data-test={TestIds.SupportCasePrioritySelect}
            renderValue={(value) =>
              getPriorityLabel(value as GQSupportCasePriority)
            }
          >
            <MenuItem
              value={GQSupportCasePriority.Low}
              sx={{
                height: 40,
                gap: 1,
                '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
              }}
              onMouseEnter={() =>
                setSelectedPriority(GQSupportCasePriority.Low)
              }
              onMouseLeave={() => setSelectedPriority(null)}
              onFocus={() => setSelectedPriority(GQSupportCasePriority.Low)}
              onBlur={() => setSelectedPriority(null)}
            >
              <ListItemIcon>
                <SupportCasePriorityIcon priority={GQSupportCasePriority.Low} />
              </ListItemIcon>
              <ListItemText>
                {getPriorityLabel(GQSupportCasePriority.Low)}
              </ListItemText>
            </MenuItem>

            <MenuItem
              value={GQSupportCasePriority.Medium}
              sx={{
                height: 40,
                gap: 1,
                '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
              }}
              onMouseEnter={() =>
                setSelectedPriority(GQSupportCasePriority.Medium)
              }
              onMouseLeave={() => setSelectedPriority(null)}
              onFocus={() => setSelectedPriority(GQSupportCasePriority.Medium)}
              onBlur={() => setSelectedPriority(null)}
            >
              <ListItemIcon>
                <SupportCasePriorityIcon
                  priority={GQSupportCasePriority.Medium}
                />
              </ListItemIcon>
              <ListItemText>
                {getPriorityLabel(GQSupportCasePriority.Medium)}
              </ListItemText>
            </MenuItem>
            <MenuItem
              value={GQSupportCasePriority.High}
              sx={{
                height: 40,
                gap: 1,
                '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
              }}
              onMouseEnter={() =>
                setSelectedPriority(GQSupportCasePriority.High)
              }
              onMouseLeave={() => setSelectedPriority(null)}
              onFocus={() => setSelectedPriority(GQSupportCasePriority.High)}
              onBlur={() => setSelectedPriority(null)}
            >
              <ListItemIcon>
                <SupportCasePriorityIcon
                  priority={GQSupportCasePriority.High}
                />
              </ListItemIcon>
              <ListItemText>
                {getPriorityLabel(GQSupportCasePriority.High)}
              </ListItemText>
            </MenuItem>
            <Stack
              sx={{
                height: '44px',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'grey05',
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
              role="status" // Indicates this is a status that may update
              aria-live="polite" // Announces changes politely
              aria-atomic="true" // Reads the entire content when it changes
            >
              {[
                { priority: null, text: getPriorityDescription(null) },
                {
                  priority: GQSupportCasePriority.High,
                  text: getPriorityDescription(GQSupportCasePriority.High),
                },
                {
                  priority: GQSupportCasePriority.Medium,
                  text: getPriorityDescription(GQSupportCasePriority.Medium),
                },
                {
                  priority: GQSupportCasePriority.Low,
                  text: getPriorityDescription(GQSupportCasePriority.Low),
                },
              ].map(({ priority, text }) => (
                <Typography
                  key={priority ?? 'default'}
                  sx={{
                    fontFamily: CODE_FONT_FAMILY,
                    fontSize: 12,
                    textAlign: 'center',
                    textWrap: 'pretty',
                    transition: 'opacity 0.15s ease-out',
                    opacity: selectedPriority === priority ? 1 : 0,
                    position: 'absolute',
                    top: '0',
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    // Hide from screen readers when not visible
                    visibility:
                      selectedPriority === priority ? 'visible' : 'hidden',
                    // Prevent interaction when not visible
                    pointerEvents:
                      selectedPriority === priority ? 'auto' : 'none',
                  }}
                  variant="body3"
                  color="text.secondary"
                  aria-hidden={selectedPriority !== priority} // Hide from screen readers when not visible
                >
                  {text}
                </Typography>
              ))}
              {/* Add a visually hidden element that announces the current selection */}
              <Box component="span" sx={{ display: 'none' }} role="status">
                {selectedPriority
                  ? `Selected priority: ${selectedPriority}. ${getPriorityDescription(selectedPriority)}`
                  : getPriorityDescription(null)}
              </Box>
            </Stack>
          </SelectField>
          <TextFieldMultiline
            required
            id="description"
            label={
              <Trans context="Form label for support case description">
                Message
              </Trans>
            }
            placeholder={t({
              message: 'Add more details here…',
              context: 'Placeholder for support case description',
            })}
            style={{
              gap: 1,
              minHeight: 96,
              maxHeight: 256,
              overflowY: 'scroll',
              paddingTop: '8px',
            }}
            maxLength={MAX_SUPPORT_CASE_DESCRIPTION_LENGTH}
            data-test={TestIds.SupportCaseDescriptionInput}
          />
        </DialogForm>
      )}
    </Formik>
  );
}

export const useSupportCaseDialog =
  createDialogHook<SupportCaseDialogProps>(NewSupportCaseDialog);
