import {
  Action,
  ResourceSpecificPermissionType,
  ResourcePermissionConfig,
  TopLevelResourceType,
} from './permissionTypes';
import { GQPermissionObjectType, GQPermissionType } from '../generated/graphql';
import { BadInputError } from '@watershed/errors/BadInputError';

/**
 * This is a mapping of the legacy PermissionTypes that correspond
 * to a resource level permission to the resource type and action.
 */
export const RESOURCE_LEVEL_PERMISSION_TYPES: Record<
  ResourceSpecificPermissionType,
  { resourceType: GQPermissionObjectType; action: Action }
> = {
  [GQPermissionType.ManageDataset]: {
    resourceType: GQPermissionObjectType.Dataset,
    action: Action.Manage,
  },
  [GQPermissionType.ManageDatasource]: {
    resourceType: GQPermissionObjectType.Datasource,
    action: Action.Manage,
  },
  [GQPermissionType.ApproveDatasource]: {
    resourceType: GQPermissionObjectType.Datasource,
    action: Action.Approve,
  },
  [GQPermissionType.EditReportQuestionInstance]: {
    resourceType: GQPermissionObjectType.ReportQuestionInstance,
    action: Action.Edit,
  },
  [GQPermissionType.ViewReportQuestionInstance]: {
    resourceType: GQPermissionObjectType.ReportQuestionInstance,
    action: Action.View,
  },
  [GQPermissionType.ManageFund]: {
    resourceType: GQPermissionObjectType.Fund,
    action: Action.Manage,
  },
  [GQPermissionType.FinanceReadOnly]: {
    resourceType: GQPermissionObjectType.Fund,
    action: Action.View,
  },
  [GQPermissionType.ManageSuppliers]: {
    resourceType: GQPermissionObjectType.Company,
    action: Action.Manage,
  },
  // TODO: uncomment when multiple footprints is permissioned
  // [GQPermissionType.ViewFootprintDetail]: {
  //   resourceType: GQPermissionObjectType.Footprint,
  //   action: Action.View,
  // },
};

export const getLegacyPermissionFromResourceAndAction = (
  resourceType: GQPermissionObjectType,
  action: Action
): GQPermissionType => {
  const permission = Object.entries(RESOURCE_LEVEL_PERMISSION_TYPES).find(
    ([_, config]) => {
      return config.resourceType === resourceType && config.action === action;
    }
  );
  if (!permission) {
    if (
      resourceType === GQPermissionObjectType.Dataset &&
      action === Action.Approve
    ) {
      return GQPermissionType.ApproveDatasource;
    }
    throw new BadInputError(
      `No permission found for resource type ${resourceType} and action ${action}`
    );
  }

  return permission[0] as GQPermissionType;
};

/**
 * Here we configure the high level resource types with their
 * - (optional) subResourceType
 * - actions that are allowed on the resource
 * - (optional) actionInheritance that allows for one action to imply another
 */
export const RESOURCE_TYPE_PERMISSION_CONFIGS: Record<
  TopLevelResourceType,
  ResourcePermissionConfig
> = {
  [GQPermissionObjectType.Dataset]: {
    childResourceType: GQPermissionObjectType.Datasource,
    actions: [Action.Manage, Action.Approve],
  },
  [GQPermissionObjectType.ReportQuestionInstance]: {
    actions: [Action.View, Action.Edit],
  },
  [GQPermissionObjectType.Fund]: {
    actions: [Action.Manage, Action.View],
  },
  [GQPermissionObjectType.Company]: {
    actions: [Action.Manage],
  },
  [GQPermissionObjectType.EngagementTask]: {
    actions: [Action.Manage],
  },
  [GQPermissionObjectType.Footprint]: {
    actions: [Action.View],
  },
};
