/*
    For historical reasons, this file is still called naics.ts, and several of our models
    (e.g. asset_corporate) have a naics_code field in the database.

    However, it is actually BEA codes that are used by CEE.

    As of February 2023, users had to select one of the COMPANY_ESTIMATE_INDUSTRY_CODES
    for their industry code. But we expanded the set of available codes by performing
    mappings between:

      1. The complete NAICS code set and BEA
      2. GICS codes and BEA
      3. NACE codes and BEA
      4. SICS codes and BEA
      5. ANZSIC codes and BEA
      6. UKSIC codes and BEA (09/2024)

    As of this change, users are presented with a single list of all NAICS and GICS codes
    all together, since the field is an autocomplete, and it would add unwanted to complexity
    to ask users to choose which system to use.

    We now store a raw NAICS code in the case of NAICS or GICS_[code] in the case of GICS.
    This leaves open the possibility of adding other mappings in the future.

    When we run CEE, we map the stored value to an EEIO supported member of the
    COMPANY_ESTIMATE_INDUSTRY_CODES list.
 */

import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { NACE_CODES } from './nace';
import { GICS_CODES, HISTORICAL_GICS_CODES } from './gics';
import { NAICS_CODES } from './naics';
import { SICS_CODES } from './sics';
import { ANZSIC_CODES } from './anzsic';
import { UKSIC_CODES } from './uksic';

import {
  COMPANY_ESTIMATE_INDUSTRY_CODES,
  NAICS_SECTORS,
  NAICS_SUBSECTORS,
} from './otherCodes';
import { SP_NAICS_CODES } from './sp_naics';

export type WeightedBeaCodes = Partial<{
  [key in keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES]: number;
}>;

export function getIndustrySectorCode(industryCode: string): string {
  const beaCode = getBeaCodeFromIndustryCode(industryCode);
  return beaCode.substring(0, 2);
}

function sectorForNaics(naicsCode: string | null): string {
  return (naicsCode && NAICS_SECTORS[naicsCode.substring(0, 2)]) ?? 'unknown';
}

export function getNaicsSectorForIndustryCode(
  industryCode: string | null
): string {
  if (!industryCode) {
    return 'unknown';
  }
  const beaCode = getBeaCodeFromIndustryCode(industryCode);
  return sectorForNaics(beaCode);
}

// Cache for `findIndustryCodeFromCodeOrLabel`
const industryCodeLookupMap = new Map<string, string>();

/**
 * All industry codes across all taxonomies.
 */
export const INDUSTRY_CODE_LIST_INCLUDING_DEPRECATED = sortBy(
  uniq([
    ...Object.keys(COMPANY_ESTIMATE_INDUSTRY_CODES),
    ...Object.keys(NAICS_CODES),
    ...Object.keys(GICS_CODES),
    ...Object.keys(NACE_CODES),
    ...Object.keys(SICS_CODES),
    ...Object.keys(ANZSIC_CODES),
    ...Object.keys(UKSIC_CODES),
  ]),
  (code) => getIndustryFromIndustryCode(code)
);

export const INDUSTRY_CODE_LIST =
  INDUSTRY_CODE_LIST_INCLUDING_DEPRECATED.filter(
    (value) => !HISTORICAL_GICS_CODES[value]
  );

const INDUSTRY_CODE_SET_INCLUDING_DEPRECATED = new Set(
  INDUSTRY_CODE_LIST_INCLUDING_DEPRECATED
);

export function isValidIndustryCode(code?: string | null): boolean {
  return (
    !!code && INDUSTRY_CODE_SET_INCLUDING_DEPRECATED.has(code.toUpperCase())
  );
}

export function findIndustryCodeFromCodeOrLabel(
  input: string
): string | undefined {
  if (isValidIndustryCode(input)) return input.toUpperCase();

  // Lazily initialize cache
  if (industryCodeLookupMap.size === 0) {
    function addIfNotExists(key: string, code: string) {
      if (!industryCodeLookupMap.has(key)) {
        industryCodeLookupMap.set(key, code);
      }
    }
    for (const [code, label] of Object.entries(
      COMPANY_ESTIMATE_INDUSTRY_CODES
    )) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
    for (const [code, { label }] of Object.entries(NAICS_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
    for (const [code, { label }] of Object.entries(GICS_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
    for (const [code, { label }] of Object.entries(NACE_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
    for (const [code, { label }] of Object.entries(SICS_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(getIndustryLabelFromIndustryCode(code), code);
    }
    for (const [code, { label }] of Object.entries(ANZSIC_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
    for (const [code, { label }] of Object.entries(UKSIC_CODES)) {
      addIfNotExists(label, code);
      addIfNotExists(formatLabelAndCode(label, code), code);
    }
  }

  return industryCodeLookupMap.get(input);
}

function formatLabelAndCode(label: string, code: string): string {
  const codeUpperCase = code.toUpperCase();
  return `${label} (${codeUpperCase})`;
}

/**
 * Returns a CEE compatible code
 * When one code maps to multiple CEE codes, it returns the code with the highest weight
 * If the code is not found, it throws an error
 */
export function getBeaCodeFromIndustryCode(
  code: string
): keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES {
  const codeUpperCase = code.toUpperCase();
  if (
    COMPANY_ESTIMATE_INDUSTRY_CODES[
      codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ]
  ) {
    return codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES;
  }
  if (NAICS_CODES[codeUpperCase]) {
    const beaCodes = NAICS_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  if (GICS_CODES[codeUpperCase]) {
    const beaCodes = GICS_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  if (NACE_CODES[codeUpperCase]) {
    const beaCodes = NACE_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  if (SICS_CODES[codeUpperCase]) {
    const beaCodes = SICS_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  if (ANZSIC_CODES[codeUpperCase]) {
    const beaCodes = ANZSIC_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  if (UKSIC_CODES[codeUpperCase]) {
    const beaCodes = UKSIC_CODES[codeUpperCase].BEA_CODES;
    return getMaxBeaCode(beaCodes);
  }
  throw new Error(`Invalid industry code: ${codeUpperCase}`);
}

function getMaxBeaCode(
  beaCodes: WeightedBeaCodes
): keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES {
  const [highestWeightedBeaCode] = Object.entries(beaCodes).reduce((a, b) =>
    a[1] > b[1] ? a : b
  );
  return highestWeightedBeaCode as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES;
}

export function getMultiBeaCodeFromIndustryCode(
  code: string
): WeightedBeaCodes {
  const codeUpperCase = code.toUpperCase();
  if (
    COMPANY_ESTIMATE_INDUSTRY_CODES[
      codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ]
  ) {
    return {
      [codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES]: 1.0,
    };
  }
  if (NAICS_CODES[codeUpperCase]) {
    return NAICS_CODES[codeUpperCase].BEA_CODES;
  }
  if (GICS_CODES[codeUpperCase]) {
    return GICS_CODES[codeUpperCase].BEA_CODES;
  }
  if (NACE_CODES[codeUpperCase]) {
    return NACE_CODES[codeUpperCase].BEA_CODES;
  }
  if (SICS_CODES[codeUpperCase]) {
    return SICS_CODES[codeUpperCase].BEA_CODES;
  }
  if (ANZSIC_CODES[codeUpperCase]) {
    return ANZSIC_CODES[codeUpperCase].BEA_CODES;
  }
  if (UKSIC_CODES[codeUpperCase]) {
    return UKSIC_CODES[codeUpperCase].BEA_CODES;
  }
  throw new Error(`Invalid industry code: ${codeUpperCase}`);
}

export function getTaxonomyFromIndustryCode(code: string): string {
  const codeUpperCase = code.toUpperCase();
  if (
    COMPANY_ESTIMATE_INDUSTRY_CODES[
      codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ]
  )
    return 'BEA';
  if (NAICS_CODES[codeUpperCase]) return 'NAICS';
  if (GICS_CODES[codeUpperCase]) return 'GICS';
  if (NACE_CODES[codeUpperCase]) return 'NACE';
  if (SICS_CODES[codeUpperCase]) return 'SICS';
  if (ANZSIC_CODES[codeUpperCase]) return 'ANZSIC';
  if (UKSIC_CODES[codeUpperCase]) return 'UKSIC';
  return '';
}

// Just the industry string itself
export function getIndustryFromIndustryCode(code: string): string {
  const codeUpperCase = code.toUpperCase();
  if (
    COMPANY_ESTIMATE_INDUSTRY_CODES[
      codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ]
  )
    return COMPANY_ESTIMATE_INDUSTRY_CODES[
      codeUpperCase as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ];
  if (NAICS_CODES[codeUpperCase]) return NAICS_CODES[codeUpperCase].label;
  if (GICS_CODES[codeUpperCase]) return GICS_CODES[codeUpperCase].label;
  if (NACE_CODES[codeUpperCase]) return NACE_CODES[codeUpperCase].label;
  if (SICS_CODES[codeUpperCase]) return SICS_CODES[codeUpperCase].label;
  if (ANZSIC_CODES[codeUpperCase]) return ANZSIC_CODES[codeUpperCase].label;
  if (UKSIC_CODES[codeUpperCase]) return UKSIC_CODES[codeUpperCase].label;
  return '';
}

type IndustryCodeLabelOptions = {
  includeCode?: boolean;
  includeTaxonomy?: boolean;
};
const DEFAULT_INDUSTRY_CODE_LABEL_OPTIONS: IndustryCodeLabelOptions = {
  includeCode: true,
  includeTaxonomy: false,
};

export function getIndustryLabelFromIndustryCode(
  code: string,
  options: IndustryCodeLabelOptions = DEFAULT_INDUSTRY_CODE_LABEL_OPTIONS
): string {
  const codeUpperCase = code.toUpperCase().replace('NAICS_', '');
  const label = getIndustryFromIndustryCode(codeUpperCase);
  const prefix = options.includeTaxonomy
    ? `[${getTaxonomyFromIndustryCode(codeUpperCase)}] `
    : '';
  const suffix = options.includeCode
    ? formatLabelAndCode(label, codeUpperCase)
    : label;
  return `${prefix}${suffix}`;
}

export function safelyGetIndustryLabelFromIndustryCode(
  code: string | null,
  options: IndustryCodeLabelOptions = DEFAULT_INDUSTRY_CODE_LABEL_OPTIONS
): string {
  return code ? getIndustryLabelFromIndustryCode(code, options) : '';
}

export function getMostSpecificNaicsIndustry(
  naicsCode: string | null
): string | null {
  const formattedNaicsCode = naicsCode?.toUpperCase();

  const naicsIndustry =
    formattedNaicsCode &&
    COMPANY_ESTIMATE_INDUSTRY_CODES[
      // reasonable cast here because we're testing for the key in the object
      formattedNaicsCode as keyof typeof COMPANY_ESTIMATE_INDUSTRY_CODES
    ];
  if (naicsIndustry) {
    return naicsIndustry;
  }

  const naicsSubSector =
    formattedNaicsCode && NAICS_SUBSECTORS[formattedNaicsCode.slice(0, 3)];
  if (naicsSubSector) {
    return naicsSubSector;
  }

  const naicsSector =
    formattedNaicsCode && NAICS_SECTORS[formattedNaicsCode.slice(0, 2)];
  return naicsSector ?? null;
}

/**
 * Since we ingest company NAICS codes from S&P, we want to make sure
 * that we have a mapping for all of them. This function first checks
 * the existing mappings and then the remaining S&P mappings for NAICS.
 * This is used specifically for _companies_ and shouldn't be used elsewhere.
 */
export function getIndustryLabelFromNaicsCode(
  code: string,
  options: IndustryCodeLabelOptions = DEFAULT_INDUSTRY_CODE_LABEL_OPTIONS
): string {
  if (getIndustryFromIndustryCode(code) !== '') {
    return getIndustryLabelFromIndustryCode(code, options);
  } else if (SP_NAICS_CODES[code]) {
    const label = SP_NAICS_CODES[code];
    const prefix = options.includeTaxonomy ? `[NAICS] ` : '';
    const suffix = options.includeCode
      ? formatLabelAndCode(label, code)
      : label;
    return `${prefix}${suffix}`;
  }
  return '';
}
