export default function propertyIsNotNullish<T, K extends keyof T>(key: K) {
  return function propertyIsNotNullishChecker(
    x: T | null | undefined
  ): x is NonNullableWithNonNullableProperty<T, K> {
    return x != null && x[key] != null;
  };
}

export type NonNullableWithNonNullableProperty<
  T,
  K extends keyof T,
> = NonNullable<T> & Required<{ [P in K]: NonNullable<T[K]> }>;

export function ifPropertyIsNotNullish<T, K extends keyof T>(
  x: T,
  key: K
): x is NonNullableWithNonNullableProperty<T, K> {
  return propertyIsNotNullish<T, K>(key)(x);
}

export function assertPropertyIsNotNullish<T, K extends keyof T>(
  x: T,
  key: K
): asserts x is NonNullableWithNonNullableProperty<T, K> {
  if (!propertyIsNotNullish<T, K>(key)(x)) {
    throw new Error(`Property ${String(key)} is nullish`);
  }
}
