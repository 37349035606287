/* @skip-file-for-translation */
import { Box, Chip, MenuItem, Checkbox, Stack, Select } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';
import { FeatureFlagsMap, useFeatureFlagContext } from '../utils/FeatureFlag';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import useLocale from '@watershed/intl/frontend/useLocale';
import { useLingui } from '@lingui/react/macro';
import { Searcher } from 'fast-fuzzy';
import { TextFieldSearch } from '@watershed/ui-core/components/Form/TextField';
import { useMemo, useState } from 'react';
import assertNever from '@watershed/shared-util/assertNever';

type FilterState = 'all' | 'active' | 'overridden';

// Overrides get cleared out from flagOverrides once they're returned
// to the real flag state. But it's still possible you'd end up with
// an "override" that is the same as the actual flag value, if the real
// flag value changes after an override is applied, and the page reloads.
// In that case the override has no effect.
function getUsingRealFlagValue(
  flag: GQFlags,
  flagOverrides: FeatureFlagsMap,
  originalFlags: FeatureFlagsMap
) {
  return (
    !flagOverrides.has(flag) ||
    !!originalFlags.get(flag) === flagOverrides.get(flag)
  );
}

export default function FeatureFlagOverrideDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const { flags, flagOverrides, setOverride, originalFlags } =
    useFeatureFlagContext();

  const locale = useLocale();

  const [searchTerm, setSearchTerm] = useState('');

  const [filterState, setFilterState] = useState<FilterState>('all');

  const initialFlags = useMemo(() => {
    switch (filterState) {
      case 'all':
        return Object.values(GQFlags);
      case 'active':
        return Object.values(GQFlags).filter((flag) => !!flags.get(flag));
      case 'overridden':
        return Object.values(GQFlags).filter(
          (flag) => !getUsingRealFlagValue(flag, flagOverrides, originalFlags)
        );
      default:
        assertNever(filterState);
    }
  }, [filterState, flags, flagOverrides, originalFlags]);

  const searcher = useMemo(() => new Searcher(initialFlags), [initialFlags]);

  const searchedFlags = useMemo(() => {
    if (searchTerm) {
      return searcher.search(searchTerm, { threshold: 0.7 });
    }
    return initialFlags;
  }, [searchTerm, searcher, initialFlags]);

  const sortedFlags = useMemo(() => {
    return searchedFlags.sort((flagA, flagB) => {
      return flagA.localeCompare(flagB, locale);
    });
  }, [searchedFlags, locale]);

  const { t } = useLingui();

  return (
    <Dialog
      onClose={onClose}
      header={{
        title: (
          <Trans context="Title for feature flag dialog">
            Feature flag overrides
          </Trans>
        ),
        subtitle: (
          <Stack gap={2}>
            <Box>
              <Trans>
                Override a feature flag to preview how a new feature would
                appear to this org. Overrides expire at the end of your browser
                session.
              </Trans>
              <ErrorBox level="warning" variant="text" sx={{ marginTop: 2 }}>
                <Trans context="feature flags means we can turn a feature on or off">
                  This only affects client-side flag checks!
                </Trans>
              </ErrorBox>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: '4fr 1fr',
              }}
            >
              <TextFieldSearch
                autoFocus
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t({
                  message: 'Type to search',
                  context: 'Placeholder for feature flag search box',
                })}
                id="feature-flag-search"
                label={
                  <Trans context="Label for feature flag search box">
                    Filter feature flags
                  </Trans>
                }
              />
              <Select
                onChange={(v) => setFilterState(v.target.value as FilterState)}
                value={filterState}
                defaultValue="all"
              >
                <MenuItem value="all">
                  <Trans context="Show all flags">Show All</Trans>
                </MenuItem>
                <MenuItem value="active">
                  <Trans context="Show active feature flags">Show Active</Trans>
                </MenuItem>
                <MenuItem value="overridden">
                  <Trans context="Show overridden feature flags">
                    Show Overridden
                  </Trans>
                </MenuItem>
              </Select>
            </Box>
          </Stack>
        ),
      }}
    >
      <Box
        sx={{
          height: '50vh',
        }}
      >
        {sortedFlags.length > 0 ? (
          sortedFlags.map((flag) => {
            const usingRealFlagValue = getUsingRealFlagValue(
              flag,
              flagOverrides,
              originalFlags
            );
            return (
              <Box key={flag}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={!!flags.get(flag)}
                    onChange={(_event, checked) => {
                      setOverride(flag, checked);
                    }}
                  />
                  {flag}
                  {!usingRealFlagValue && (
                    <Chip
                      sx={{ marginLeft: 1 }}
                      label={
                        <Trans context="Override a feature flag">
                          Override
                        </Trans>
                      }
                      color="error"
                    />
                  )}
                </label>
              </Box>
            );
          })
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Trans context="Message shown when search term doesn't match any flags">
              No flags found
            </Trans>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
