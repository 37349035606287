import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CommandPaletteHistoryEntry } from './types';
import CommandPalette, { CommandPaletteRef } from './CommandPalette';
import useKeydown from '../../hooks/useKeydown';

interface AppCommandPaletteConfigLayer<C extends {}> {
  id: string;
  enabled: boolean;
  initialLocation?: CommandPaletteHistoryEntry<C>;
  priority: number;
}

interface AppCommandPaletteContextShape {
  pushConfigLayer: (layer: AppCommandPaletteConfigLayer<any>) => void;
  removeConfigLayer: (id: string) => void;
  open: () => void;
  openAt: (location: CommandPaletteHistoryEntry<any>) => void;
}

const AppCommandPaletteContext =
  createContext<AppCommandPaletteContextShape | null>(null);

interface AppCommandPaletteProviderProps {
  children: React.ReactNode;
}

export function AppCommandPaletteProvider({
  children,
}: AppCommandPaletteProviderProps) {
  const commandPaletteRef = useRef<CommandPaletteRef>(null);

  const [configLayers, setConfigLayers] = useState<
    Array<AppCommandPaletteConfigLayer<any>>
  >([]);

  useKeydown((event: KeyboardEvent) => {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      commandPaletteRef.current?.toggle();
    }
  });

  const contextValue = useMemo(() => {
    return {
      pushConfigLayer: (layer: AppCommandPaletteConfigLayer<any>) =>
        setConfigLayers((layers) => [...layers, layer]),
      removeConfigLayer: (id: string) =>
        setConfigLayers((layers) => layers.filter((layer) => layer.id !== id)),
      open: () => commandPaletteRef.current?.open(),
      openAt: (location: CommandPaletteHistoryEntry<any>) =>
        commandPaletteRef.current?.openAt(location),
    };
  }, []);

  // Current layer is the highest priority enabled layer, using natural order
  // as a tiebreaker (favour latest)
  const currentLayer = useMemo(() => {
    return configLayers
      .filter((layer) => layer.enabled)
      .sort((a, b) => {
        if (a.priority === b.priority) {
          return configLayers.indexOf(b) - configLayers.indexOf(a);
        }

        return b.priority - a.priority;
      })[0];
  }, [configLayers]);
  const initialLocation = currentLayer?.initialLocation;

  return (
    <>
      {initialLocation ? (
        <CommandPalette
          ref={commandPaletteRef}
          initialNavigationState={[initialLocation]}
        />
      ) : null}
      <AppCommandPaletteContext.Provider value={contextValue}>
        {children}
      </AppCommandPaletteContext.Provider>
    </>
  );
}

export function useAppCommandPalette<C extends {}>({
  enabled = true,
  initialLocation,
  priority = 1,
}: {
  enabled: boolean;
  initialLocation: CommandPaletteHistoryEntry<C>;
  priority?: number;
}) {
  const layerId = useId();
  const context = useContext(AppCommandPaletteContext);

  if (!context) {
    throw new Error(
      'useAppCommandPalette must be used within AppCommandPaletteContext'
    );
  }

  useEffect(() => {
    context.pushConfigLayer({
      id: layerId,
      enabled,
      initialLocation,
      priority,
    });

    return () => context.removeConfigLayer(layerId);
  }, [layerId, context, enabled, initialLocation, priority]);

  return context;
}

export function useCommandPaletteTrigger() {
  const context = useContext(AppCommandPaletteContext);

  if (!context) {
    throw new Error(
      'useCommandPaletteTrigger must be used within AppCommandPaletteContext'
    );
  }

  return context.openAt;
}
