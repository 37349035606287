/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import z from '../zodWithOpenApi';
import {
  GQBuildingSizeUnit,
  GQCountryAlpha2,
  GQCurrencyCode,
  GQFinanceAuditAction,
  GQFundAssetClass,
  GQOneSchemaDataFormat,
  GQEstimationMethodology,
  GQFinanceMetric,
  GQGridColumnFormat,
  GQFinanceTagType,
  GQFinanceChartKind,
  GQScope3OverrideKind,
  GQTaskWatershedProcessStateSimplified,
  GQWatershedFootprintReviewRequestStatus,
  GQIciAlignmentStatus,
  GQFinanceHighchartsChartKind,
} from '../../generated/graphql';
import { getZodEnumValuesFromEnum } from '../../utils/zod';
import {
  InputKey,
  OutputKey,
  getFinanceFieldHeaderName,
  getFinanceNonNegativeFieldErrorMessage,
  getFinanceIntegerFieldErrorMessage,
  getFinanceNonEmptyStringErrorMessage,
  getFinancePositiveFieldErrorMessage,
} from '../../fund/financeFieldRegistry';
import { zodId, zodNonEmptyString } from '../apiSchemaCommon';
import {
  corporateAssetClasses,
  realEstateAssetClasses,
  realEstateAssetClassesWithMortgages,
} from '../../finance/utils/assetTypeUtils';

export function makeNonEmptyStringSchema(key: InputKey | OutputKey) {
  return z
    .string({
      invalid_type_error: getFinanceNonEmptyStringErrorMessage(key, {
        // TODO(shouldRenameFund): maybe yes, if it has "fund"!
        shouldRenameFund: false,
      }),
      required_error: getFinanceNonEmptyStringErrorMessage(key, {
        // TODO(shouldRenameFund): maybe yes, if it has "fund"!
        shouldRenameFund: false,
      }),
    })
    .trim()
    .min(1)
    .describe(
      getFinanceFieldHeaderName(key, {
        // TODO(shouldRenameFund): maybe yes, if it has "fund"!
        shouldRenameFund: false,
      })
    );
}

// can include 0
export function makeNonNegativeNullableNumberSchema(key: InputKey | OutputKey) {
  return z.coerce
    .number()
    .nonnegative(
      getFinanceNonNegativeFieldErrorMessage(key, { shouldRenameFund: false })
    )
    .describe(getFinanceFieldHeaderName(key, { shouldRenameFund: false }))
    .nullable();
}

// can't include 0
export function makePositiveNullableNumberSchema(key: InputKey | OutputKey) {
  return z.coerce
    .number()
    .nonnegative(
      getFinanceNonNegativeFieldErrorMessage(key, { shouldRenameFund: false })
    )
    .positive(
      getFinancePositiveFieldErrorMessage(key, { shouldRenameFund: false })
    )
    .describe(getFinanceFieldHeaderName(key, { shouldRenameFund: false }))
    .nullable();
}

// TODO: don't use coerce here! That will make the schema nullable in eyes of Zod
// because null in typescript is casted to zero!
export function makeNonNegativeNumberSchema(key: InputKey | OutputKey) {
  return z.coerce
    .number()
    .nonnegative(
      getFinanceNonNegativeFieldErrorMessage(key, { shouldRenameFund: false })
    )
    .describe(getFinanceFieldHeaderName(key, { shouldRenameFund: false }))
    .nullable();
}

export function makeNullableIntNumberSchema(key: InputKey | OutputKey) {
  return z.coerce
    .number()
    .int(getFinanceIntegerFieldErrorMessage(key, { shouldRenameFund: false }))
    .nonnegative(
      getFinanceNonNegativeFieldErrorMessage(key, { shouldRenameFund: false })
    )
    .describe(getFinanceFieldHeaderName(key, { shouldRenameFund: false }))
    .nullable();
}

export const zodPcafScore = z.coerce.number().int().min(1).max(5);

export function makePcafScoreSchema(key: InputKey | OutputKey) {
  return zodPcafScore.describe(
    getFinanceFieldHeaderName(key, { shouldRenameFund: false })
  );
}

export function makeNullablePcafScoreSchema(key: InputKey | OutputKey) {
  return makePcafScoreSchema(key).nullable();
}

export function makeNonEmptyNullableStringSchema(key: InputKey | OutputKey) {
  return zodNonEmptyString
    .describe(
      getFinanceFieldHeaderName(key, {
        // TODO(shouldRenameFund): maybe yes, if it has "fund"!
        shouldRenameFund: false,
      })
    )
    .nullable();
}

/*
    BEWARE BREAKING CHANGES!
    ========================

    If you remove a value here and the value exists in the database,
    you have an error waiting to happen. You must clear the database
    of any values that get deleted from here. Please and thank you.
*/

export const ALL_SCOPES = ['Scope 1', 'Scope 2', 'Scope 3'] as const;

export const FINANCE_BUILDING_KINDS_H1_2023 = [
  '5+ Unit Building',
  'Apartment Unit',
  'Commercial - Uncategorized',
  'CommercialIndustrial',
  'Convenience store',
  'Convenience store with gas station',
  'Data Center - Colocated',
  'Data Center - Non-Colocated',
  'DataCenterColocation',
  'DataCenterNonColocation',
  'Education - College or university',
  'Education - Elementary or middle school',
  'Education - High school',
  'Education - Other classroom',
  'Education - Preschool or daycare',
  'Education - Uncategorized',
  'Food Sales',
  'Food Service - Fast food',
  'Food Service - Restaurant or cafeteria',
  'Food Service - Uncategorized',
  'Grocery store or food market',
  'Health Care - Inpatient',
  'Health Care - Outpatient Clinic',
  'Health Care - Outpatient Uncategorized',
  'Health Care - Uncategorized',
  'Industrial',
  'Laboratory',
  'Lodging - Dormitory or fraternity/sorority',
  'Lodging - Hotel',
  'Lodging - Motel or inn',
  'Manufactured Home',
  // 'Manufacturing Facility',
  'MedicalFacilityNonDiagnostic',
  'Mixed Use - Commercial and Residential',
  'Mixed Use - Predominantly Commercial',
  'Mixed Use - Predominantly Residential',
  'Multifamily - Condominiums',
  'Multifamily - Uncategorized',
  'Nursing Home',
  'Office',
  'Office - Administrative or Professional',
  'Office - Bank or other financial',
  'Office - Government',
  'Office - Medical non diagnostic',
  'Office - Mixed use',
  'Office - Uncategorized',
  'Other',
  'Parking Garage',
  'Public Assembly - Arena',
  'Public Assembly - Drama theater',
  'Public Assembly - Large Hall',
  'Public Assembly - Library',
  'Public Assembly - Movie Theater',
  'Public Assembly - Pool',
  'Public Assembly - Recreation',
  'Public Assembly - Stadium',
  'Public Assembly - Uncategorized',
  'Public Safety - Courthouse',
  'Public Safety - Fire or police station',
  'Public Safety - Penitentiary',
  'Public Safety - Uncategorized',
  'Religious worship',
  'Residential',
  'Residential - Unknown',
  'Restaurant',
  'Retail',
  'Retail - Big Box (> 50K sf)',
  'Retail - Enclosed mall',
  'Retail - Small Box (< 50K sf)',
  'Retail - Strip shopping mall',
  'Retail - Uncategorized',
  'Retail - Vehicle dealership/showroom',
  'Service - Repair shop',
  'Service - Uncategorized',
  'Service - Vehicle service/repair shop',
  'Single Family - Attached',
  'Single Family - Detached',
  'Single Family - Uncategorized',
  'Transportation Terminal',
  'Warehouse',
  'Warehouse - Distribution or Shipping center',
  'Warehouse - Non-refrigerated',
  'Warehouse - Refrigerated',
  'Warehouse - Self-storage',
  'Warehouse - Uncategorized',
  'WarehouseRefrigerated',
] as const;

// Not currently in GraphQL. Keep in sync with db models?
export const zodBuildingKind = z.enum(FINANCE_BUILDING_KINDS_H1_2023);

/*
    BEWARE BREAKING CHANGES!
    ========================

    If you remove a value here and the value exists in the database,
    you have an error waiting to happen. You must clear the database
    of any values that get deleted from here. Please and thank you.
*/

export const zodBuildingSubkind = z.enum([
  '311-Food',
  '3112-Grain and Oilseed Milling',
  '311221-Wet Corn Milling',
  '31131-Sugar Manufacturing',
  '3114-Fruit and Vegetable Preserving and Specialty Food',
  '3115-Dairy Product',
  '3116-Animal Slaughtering and Processing',
  '312-Beverage and Tobacco Products',
  '3121-Beverages',
  '3122-Tobacco',
  '313-Textile Mills',
  '314-Textile Product Mills',
  '315-Apparel',
  '316-Leather and Allied Products',
  '321-Wood Products',
  '321113-Sawmills',
  '3212-Veneer, Plywood, and Engineered Woods',
  '321219-Reconstituted Wood Products',
  '3219-Other Wood Products',
  '322-Paper',
  '322110-Pulp Mills',
  '322121-Paper Mills, except Newsprint',
  '322122-Newsprint Mills',
  '322130-Paperboard Mills',
  '323-Printing and Related Support',
  '324-Petroleum and Coal Products',
  '324110-Petroleum Refineries',
  '324121-Asphalt Paving Mixture and Block',
  '324122-Asphalt Shingle and Coating Materials',
  '324191-Petroleum Lubricating Oil and Grease Products',
  '324199-Other Petroleum and Coal Products',
  '325-Chemicals',
  '325110-Petrochemicals',
  '325120-Industrial Gases',
  '325180-Other Basic Inorganic Chemicals',
  '325193-Ethyl Alcohol',
  '325194-Cyclic Crudes, Intermediate and Gum and Wood Chemicals',
  '325199-Other Basic Organic Chemicals',
  '325211-Plastics Materials and Resins',
  '325212-Synthetic Rubber',
  '325220-Artificial and Synthetic Fibers and Filaments',
  '325311-Nitrogenous Fertilizers',
  '325312-Phosphatic Fertilizers',
  '3254-Pharmaceuticals and Medicines',
  '325412-Pharmaceutical Preparation',
  '325992-Photographic Film, Paper, Plate, and Chemicals',
  '326-Plastics and Rubber Products',
  '327-Nonmetallic Mineral Products',
  '327120-Clay Building Material and Refractories',
  '327211-Flat Glass',
  '327212-Other Pressed and Blown Glass and Glassware',
  '327213-Glass Containers',
  '327215-Glass Products from Purchased Glass',
  '327310-Cements',
  '327410-Lime',
  '327420-Gypsum',
  '327993-Mineral Wool',
  '331-Primary Metals',
  '331110-Iron and Steel Mills and Ferroalloys',
  '3312-Steel Products from Purchased Steel',
  '3313-Alumina and Aluminum',
  '331314-Secondary Smelting and Alloying of Aluminum',
  '331315-Aluminum Sheet, Plate and Foils',
  '331318-Other Aluminum Rolling, Drawing and Extruding',
  '3314-Nonferrous Metals, except Aluminum',
  '331410-Nonferrous Metal (except Aluminum) Smelting and Refining',
  '3315-Foundries',
  '331511-Iron Foundries',
  '331523-Nonferrous Metal Die-Casting Foundries',
  '331524-Aluminum Foundries, except Die-Casting',
  '332-Fabricated Metal Products',
  '333-Machinery',
  '334-Computer and Electronic Products',
  '334413-Semiconductors and Related Devices',
  '335-Electrical Equip., Appliances, and Components',
  '336-Transportation Equipment',
  '336111-Automobiles',
  '336112-Light Trucks and Utility Vehicles',
  '3364-Aerospace Product and Parts',
  '336411-Aircraft',
  '337-Furniture and Related Products',
  '339-Miscellaneous',
]);

/*
    BEWARE BREAKING CHANGES!
    ========================

    If you remove a value here and the value exists in the database,
    you have an error waiting to happen. You must clear the database
    of any values that get deleted from here. Please and thank you.
*/

export const zodBuildingSizeUnit = z
  .enum(getZodEnumValuesFromEnum(GQBuildingSizeUnit))
  .default(GQBuildingSizeUnit.SquareFeet);

export const zodFinanceMetric = z.enum(
  getZodEnumValuesFromEnum(GQFinanceMetric)
);

export const financeChartKind = z.enum(
  getZodEnumValuesFromEnum(GQFinanceChartKind)
);

export const biChartKind = z.enum(
  getZodEnumValuesFromEnum(GQFinanceHighchartsChartKind)
);

/*
    BEWARE BREAKING CHANGES!
    ========================

    If you remove a value here and the value exists in the database,
    you have an error waiting to happen. You must clear the database
    of any values that get deleted from here. Please and thank you.
*/

export const zodGridColumnFormat = z.enum(
  getZodEnumValuesFromEnum(GQGridColumnFormat)
);

export const zodFinanceFlagType = z.enum(
  getZodEnumValuesFromEnum(GQFinanceTagType)
);

export const zodAssetClass = z.enum(getZodEnumValuesFromEnum(GQFundAssetClass));

export function getZodAssetClass(
  isCorporateAsset: boolean,
  isRealEstateAsset: boolean,
  canAccessMortgages: boolean
) {
  if (isCorporateAsset) {
    return z.enum(corporateAssetClasses);
  }
  if (isRealEstateAsset) {
    return canAccessMortgages
      ? z.enum(realEstateAssetClassesWithMortgages)
      : z.enum(realEstateAssetClasses);
  }
  return zodAssetClass;
}

export const zodFinanceAuditAction = z.enum(
  getZodEnumValuesFromEnum(GQFinanceAuditAction)
);
export const zodCurrencyCode = z.enum(getZodEnumValuesFromEnum(GQCurrencyCode));

export const zodEstimationMethodology = z.enum(
  getZodEnumValuesFromEnum(GQEstimationMethodology)
);

export const zodScope3OverrideKind = z.enum(
  getZodEnumValuesFromEnum(GQScope3OverrideKind)
);

export const zodCountryAlpha2 = z
  .enum(getZodEnumValuesFromEnum(GQCountryAlpha2))
  .describe(
    getFinanceFieldHeaderName(InputKey.countryAlpha2, {
      shouldRenameFund: false,
    })
  );

export const zodSovereignEntity = z.enum([
  ...getZodEnumValuesFromEnum(GQCountryAlpha2),
  'EU',
]);

export const zodOneSchemaDataFormat = z.enum(
  getZodEnumValuesFromEnum(GQOneSchemaDataFormat)
);

export const zodIciAlignmentStatus = z.enum(
  getZodEnumValuesFromEnum(GQIciAlignmentStatus)
);

export const financeFilterOptionSchema = z.record(
  z.string(),
  z.object({
    label: z.string(),
    options: z.array(
      z.object({ id: z.coerce.string(), label: z.coerce.string() })
    ),
    tagType: z.enum(getZodEnumValuesFromEnum(GQFinanceTagType)).optional(),
  })
);

// TODO: In the future, we may also want to support non-strings here. This should
// work for tags right now, but may not work for other filters.
export const financeFilterSchema = z.record(
  z.string(),
  z.array(z.string()).nullish()
);

export type FinanceFiltersType = z.TypeOf<typeof financeFilterSchema>;

// We pass around this uncombined set of filters because it can be useful
// to know where the origination of the filter was (to know if we can remove it, etc).
export type FullFilters = {
  viewFilters: FinanceFiltersType;
  routeFilters: FinanceFiltersType;
  queryParamFilters: FinanceFiltersType;
};

export const financeSavedViewRequiredFields = z.object({
  orgId: zodId,
  id: zodId,
  createdAt: z.date(),
  updatedAt: z.date(),
  name: z.string(),
});

export const financeChartConfig = z.object({
  metric: z.string().min(1),
  dimension: z.string().min(1),
  chartKind: biChartKind.nullish(),
  chartType: financeChartKind.nullish(),
});

export type FinanceChartConfigType = z.TypeOf<typeof financeChartConfig>;

export const financeChartsConfig = z.array(financeChartConfig);
export type FinanceChartsConfigType = z.TypeOf<typeof financeChartsConfig>;

export const financeSavedViewOptionalFields = z.object({
  layout: z.string(),
  metrics: z.array(z.string()),
  charts: financeChartsConfig.nullish(),
  filters: financeFilterSchema.nullish(),
});

export const financeSavedViewSchema = z
  .object({})
  .merge(financeSavedViewRequiredFields)
  .merge(financeSavedViewOptionalFields);

export const createFinanceSavedViewSchema = financeSavedViewRequiredFields
  .merge(financeSavedViewOptionalFields.partial())
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  });

export const updateFinanceSavedViewSchema = financeSavedViewSchema
  .partial()
  .extend({ id: zodId })
  .omit({
    orgId: true,
    createdAt: true,
    updatedAt: true,
  });

export const taskStatusDumpSchema = z.object({
  taskId: z.string(),
  taskName: z.string(),
  status: z.enum(
    getZodEnumValuesFromEnum(GQTaskWatershedProcessStateSimplified)
  ),
  assigneeNames: z.array(z.string()).nullable(),
  datasetName: z.string().nullable(),
});

export const footprintReviewRequestSchema = z.object({
  id: z.string(),
  footprintVersionId: z.string(),
  footprintSnapshotId: z.string().nullable(),
  footprintVersionKind: z.string().nullable(),
  status: z
    .enum(getZodEnumValuesFromEnum(GQWatershedFootprintReviewRequestStatus))
    .nullable(),
});

export const measurementProjectStatusDataSchema = z
  .object({
    taskStatusDumps: z.array(taskStatusDumpSchema),
    footprintReviewRequests: z.array(footprintReviewRequestSchema),
  })
  .nullable();

export const FINANCE_AUTOMATIC_REMINDER_UNITS = ['weeks'] as const;
export type FinanceAutomaticReminderUnits =
  (typeof FINANCE_AUTOMATIC_REMINDER_UNITS)[number];

export const financeAutomaticReminderSchema = z.array(
  z.object({
    amount: z.number(),
    unit: z.enum(FINANCE_AUTOMATIC_REMINDER_UNITS),
  })
);

export const financeImportDiffSchema = z.object({
  diffedRecords: z.array(
    z.object({
      new: z.any(),
      old: z.any(),
      errors: z.array(
        z.object({
          code: z.string(),
          message: z.string(),
        })
      ),
      warnings: z.array(
        z.object({
          code: z.string(),
          message: z.string(),
        })
      ),
      isNew: z.boolean(),
      changedValueCount: z.number(),
      name: z.string(),
    })
  ),
  unchangedRecords: z.array(
    z.object({
      errors: z.array(
        z.object({
          code: z.string(),
          message: z.string(),
        })
      ),
      warnings: z.array(
        z.object({
          code: z.string(),
          message: z.string(),
        })
      ),
      isNew: z.boolean(),
      changedValueCount: z.number(),
      name: z.string(),
    })
  ),
});

export type FinanceImportDiffType = z.TypeOf<typeof financeImportDiffSchema>;

export type FinanceAutomaticReminderConfigType = z.TypeOf<
  typeof financeAutomaticReminderSchema
>;
