import { Box, useTheme } from '@mui/material';
import StatusCriticalIcon from '@watershed/icons/components/StatusCritical';
import StatusInformationIcon from '@watershed/icons/components/StatusInformation';
import StatusMediumIcon from '@watershed/icons/components/StatusMedium';
import { GQSupportCasePriority } from '@watershed/shared-universal/generated/graphql';
import { ReactNode } from 'react';

function IconWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}

export default function SupportCasePriorityIcon({
  priority,
}: { priority: GQSupportCasePriority }) {
  const theme = useTheme();
  if (priority === GQSupportCasePriority.Low) {
    return (
      <IconWrapper>
        <StatusInformationIcon size={12} color={theme.palette.grey40} />
      </IconWrapper>
    );
  }
  if (priority === GQSupportCasePriority.Medium) {
    return (
      <IconWrapper>
        <StatusMediumIcon size={14} color={theme.palette.warning.main} />
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <StatusCriticalIcon size={13} color={theme.palette.error.dark} />
    </IconWrapper>
  );
}
