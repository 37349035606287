/*
  This file contains the NAICS codes provided by S&P data (IndustryClassCode) and their 
  corresponding descriptions that are not in naics.ts file. It primarily contains NAICS 
  codes that are 2-5 digits and a few 6 digit codes.
*/

export const SP_NAICS_CODES: { [key: string]: string } = {
  ['11']: 'Agriculture, Forestry, Fishing and Hunting',
  ['111']: 'Crop Production',
  ['1111']: 'Oilseed and Grain Farming',
  ['11111']: 'Soybean Farming',
  ['11112']: 'Oilseed (except Soybean) Farming',
  ['11113']: 'Dry Pea and Bean Farming',
  ['11114']: 'Wheat Farming',
  ['11115']: 'Corn Farming',
  ['11116']: 'Rice Farming',
  ['11119']: 'Other Grain Farming',
  ['1112']: 'Vegetable and Melon Farming',
  ['11121']: 'Vegetable and Melon Farming',
  ['1113']: 'Fruit and Tree Nut Farming',
  ['11131']: 'Orange Groves',
  ['11132']: 'Citrus (except Orange) Groves',
  ['11133']: 'Noncitrus Fruit and Tree Nut Farming',
  ['1114']: 'Greenhouse, Nursery, and Floriculture Production',
  ['11141']: 'Food Crops Grown Under Cover',
  ['11142']: 'Nursery and Floriculture Production',
  ['1119']: 'Other Crop Farming',
  ['11191']: 'Tobacco Farming',
  ['11192']: 'Cotton Farming',
  ['11193']: 'Sugarcane Farming',
  ['11194']: 'Hay Farming',
  ['11199']: 'All Other Crop Farming',
  ['112']: 'Animal Production and Aquaculture',
  ['1121']: 'Cattle Ranching and Farming',
  ['11211']: 'Beef Cattle Ranching and Farming, including Feedlots',
  ['11212']: 'Dairy Cattle and Milk Production',
  ['11213']: 'Dual-Purpose Cattle Ranching and Farming',
  ['1122']: 'Hog and Pig Farming',
  ['11221']: 'Hog and Pig Farming',
  ['1123']: 'Poultry and Egg Production',
  ['11231']: 'Chicken Egg Production',
  ['11232']: 'Broilers and Other Meat Type Chicken Production',
  ['11233']: 'Turkey Production',
  ['11234']: 'Poultry Hatcheries',
  ['11239']: 'Other Poultry Production',
  ['1124']: 'Sheep and Goat Farming',
  ['11241']: 'Sheep Farming',
  ['11242']: 'Goat Farming',
  ['1125']: 'Aquaculture',
  ['11251']: 'Aquaculture',
  ['1129']: 'Other Animal Production',
  ['11291']: 'Apiculture',
  ['11292']: 'Horses and Other Equine Production',
  ['11293']: 'Fur-Bearing Animal and Rabbit Production',
  ['11299']: 'All Other Animal Production',
  ['113']: 'Forestry and Logging',
  ['1131']: 'Timber Tract Operations',
  ['11311']: 'Timber Tract Operations',
  ['1132']: 'Forest Nurseries and Gathering of Forest Products',
  ['11321']: 'Forest Nurseries and Gathering of Forest Products',
  ['1133']: 'Logging',
  ['11331']: 'Logging',
  ['114']: 'Fishing, Hunting and Trapping',
  ['1141']: 'Fishing',
  ['11411']: 'Fishing',
  ['1142']: 'Hunting and Trapping',
  ['11421']: 'Hunting and Trapping',
  ['115']: 'Support Activities for Agriculture and Forestry',
  ['1151']: 'Support Activities for Crop Production',
  ['11511']: 'Support Activities for Crop Production',
  ['1152']: 'Support Activities for Animal Production',
  ['11521']: 'Support Activities for Animal Production',
  ['1153']: 'Support Activities for Forestry',
  ['11531']: 'Support Activities for Forestry',
  ['21']: 'Mining, Quarrying, and Oil and Gas Extraction',
  ['211']: 'Oil and Gas Extraction',
  ['2111']: 'Oil and Gas Extraction',
  ['21112']: 'Crude Petroleum Extraction',
  ['21113']: 'Natural Gas Extraction',
  ['212']: 'Mining (except Oil and Gas)',
  ['2121']: 'Coal Mining',
  ['21211']: 'Coal Mining',
  ['2122']: 'Metal Ore Mining',
  ['21221']: 'Iron Ore Mining',
  ['21222']: 'Gold Ore and Silver Ore Mining',
  ['21223']: 'Copper, Nickel, Lead, and Zinc Mining',
  ['21229']: 'Other Metal Ore Mining',
  ['2123']: 'Nonmetallic Mineral Mining and Quarrying',
  ['21231']: 'Stone Mining and Quarrying',
  ['21232']:
    'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying',
  ['21239']: 'Other Nonmetallic Mineral Mining and Quarrying',
  ['213']: 'Support Activities for Mining',
  ['2131']: 'Support Activities for Mining',
  ['21311']: 'Support Activities for Mining',
  ['22']: 'Utilities',
  ['221']: 'Utilities',
  ['2211']: 'Electric Power Generation, Transmission and Distribution',
  ['22111']: 'Electric Power Generation',
  ['22112']: 'Electric Power Transmission, Control, and Distribution',
  ['2212']: 'Natural Gas Distribution',
  ['22121']: 'Natural Gas Distribution',
  ['2213']: 'Water, Sewage and Other Systems',
  ['22131']: 'Water Supply and Irrigation Systems',
  ['22132']: 'Sewage Treatment Facilities',
  ['22133']: 'Steam and Air-Conditioning Supply',
  ['23']: 'Construction',
  ['236']: 'Construction of Buildings',
  ['2361']: 'Residential Building Construction',
  ['23611']: 'Residential Building Construction',
  ['2362']: 'Nonresidential Building Construction',
  ['23621']: 'Industrial Building Construction',
  ['23622']: 'Commercial and Institutional Building Construction',
  ['237']: 'Heavy and Civil Engineering Construction',
  ['2371']: 'Utility System Construction',
  ['23711']: 'Water and Sewer Line and Related Structures Construction',
  ['23712']: 'Oil and Gas Pipeline and Related Structures Construction',
  ['23713']: 'Power and Communication Line and Related Structures Construction',
  ['2372']: 'Land Subdivision',
  ['23721']: 'Land Subdivision',
  ['2373']: 'Highway, Street, and Bridge Construction',
  ['23731']: 'Highway, Street, and Bridge Construction',
  ['2379']: 'Other Heavy and Civil Engineering Construction',
  ['23799']: 'Other Heavy and Civil Engineering Construction',
  ['238']: 'Specialty Trade Contractors',
  ['2381']: 'Foundation, Structure, and Building Exterior Contractors',
  ['23811']: 'Poured Concrete Foundation and Structure Contractors',
  ['23812']: 'Structural Steel and Precast Concrete Contractors',
  ['23813']: 'Framing Contractors',
  ['23814']: 'Masonry Contractors',
  ['23815']: 'Glass and Glazing Contractors',
  ['23816']: 'Roofing Contractors',
  ['23817']: 'Siding Contractors',
  ['23819']: 'Other Foundation, Structure, and Building Exterior Contractors',
  ['2382']: 'Building Equipment Contractors',
  ['23821']: 'Electrical Contractors and Other Wiring Installation Contractors',
  ['23822']: 'Plumbing, Heating, and Air-Conditioning Contractors',
  ['23829']: 'Other Building Equipment Contractors',
  ['2383']: 'Building Finishing Contractors',
  ['23831']: 'Drywall and Insulation Contractors',
  ['23832']: 'Painting and Wall Covering Contractors',
  ['23833']: 'Flooring Contractors',
  ['23834']: 'Tile and Terrazzo Contractors',
  ['23835']: 'Finish Carpentry Contractors',
  ['23839']: 'Other Building Finishing Contractors',
  ['2389']: 'Other Specialty Trade Contractors',
  ['23891']: 'Site Preparation Contractors',
  ['23899']: 'All Other Specialty Trade Contractors',
  ['31']: 'Manufacturing',
  ['311']: 'Food Manufacturing',
  ['3111']: 'Animal Food Manufacturing',
  ['31111']: 'Animal Food Manufacturing',
  ['3112']: 'Grain and Oilseed Milling',
  ['31121']: 'Flour Milling and Malt Manufacturing',
  ['31122']: 'Starch and Vegetable Fats and Oils Manufacturing',
  ['31123']: 'Breakfast Cereal Manufacturing',
  ['3113']: 'Sugar and Confectionery Product Manufacturing',
  ['31131']: 'Sugar Manufacturing',
  ['31134']: 'Nonchocolate Confectionery Manufacturing',
  ['31135']: 'Chocolate and Confectionery Manufacturing',
  ['3114']: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing',
  ['31141']: 'Frozen Food Manufacturing',
  ['31142']: 'Fruit and Vegetable Canning, Pickling, and Drying',
  ['3115']: 'Dairy Product Manufacturing',
  ['31151']: 'Dairy Product (except Frozen) Manufacturing',
  ['31152']: 'Ice Cream and Frozen Dessert Manufacturing',
  ['3116']: 'Animal Slaughtering and Processing',
  ['31161']: 'Animal Slaughtering and Processing',
  ['3117']: 'Seafood Product Preparation and Packaging',
  ['31171']: 'Seafood Product Preparation and Packaging',
  ['3118']: 'Bakeries and Tortilla Manufacturing',
  ['31181']: 'Bread and Bakery Product Manufacturing',
  ['31182']: 'Cookie, Cracker, and Pasta Manufacturing',
  ['31183']: 'Tortilla Manufacturing',
  ['3119']: 'Other Food Manufacturing',
  ['31191']: 'Snack Food Manufacturing',
  ['31192']: 'Coffee and Tea Manufacturing',
  ['31193']: 'Flavoring Syrup and Concentrate Manufacturing',
  ['31194']: 'Seasoning and Dressing Manufacturing',
  ['31199']: 'All Other Food Manufacturing',
  ['312']: 'Beverage and Tobacco Product Manufacturing',
  ['3121']: 'Beverage Manufacturing',
  ['31211']: 'Soft Drink and Ice Manufacturing',
  ['31212']: 'Breweries',
  ['31213']: 'Wineries',
  ['31214']: 'Distilleries',
  ['3122']: 'Tobacco Manufacturing',
  ['31223']: 'Tobacco Manufacturing',
  ['313']: 'Textile Mills',
  ['3131']: 'Fiber, Yarn, and Thread Mills',
  ['31311']: 'Fiber, Yarn, and Thread Mills',
  ['3132']: 'Fabric Mills',
  ['31321']: 'Broadwoven Fabric Mills',
  ['31322']: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  ['31323']: 'Nonwoven Fabric Mills',
  ['31324']: 'Knit Fabric Mills',
  ['3133']: 'Textile and Fabric Finishing and Fabric Coating Mills',
  ['31331']: 'Textile and Fabric Finishing Mills',
  ['31332']: 'Fabric Coating Mills',
  ['314']: 'Textile Product Mills',
  ['3141']: 'Textile Furnishings Mills',
  ['31411']: 'Carpet and Rug Mills',
  ['31412']: 'Curtain and Linen Mills',
  ['3149']: 'Other Textile Product Mills',
  ['31491']: 'Textile Bag and Canvas Mills',
  ['31499']: 'All Other Textile Product Mills',
  ['315']: 'Apparel Manufacturing',
  ['3151']: 'Apparel Knitting Mills',
  ['31512']: 'Apparel Knitting Mills',
  ['3152']: 'Cut and Sew Apparel Manufacturing',
  ['31521']: 'Cut and Sew Apparel Contractors',
  ['31525']: 'Cut and Sew Apparel Manufacturing (except Contractors)',
  ['3159']: 'Apparel Accessories and Other Apparel Manufacturing',
  ['31599']: 'Apparel Accessories and Other Apparel Manufacturing',
  ['316']: 'Leather and Allied Product Manufacturing',
  ['3161']: 'Leather and Hide Tanning and Finishing',
  ['31611']: 'Leather and Hide Tanning and Finishing',
  ['3162']: 'Footwear Manufacturing',
  ['31621']: 'Footwear Manufacturing',
  ['3169']: 'Other Leather and Allied Product Manufacturing',
  ['31699']: 'Other Leather and Allied Product Manufacturing',
  ['32']: 'Manufacturing',
  ['321']: 'Wood Product Manufacturing',
  ['3211']: 'Sawmills and Wood Preservation',
  ['32111']: 'Sawmills and Wood Preservation',
  ['3212']: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
  ['32121']: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
  ['3219']: 'Other Wood Product Manufacturing',
  ['32191']: 'Millwork',
  ['32192']: 'Wood Container and Pallet Manufacturing',
  ['32199']: 'All Other Wood Product Manufacturing',
  ['322']: 'Paper Manufacturing',
  ['3221']: 'Pulp, Paper, and Paperboard Mills',
  ['32211']: 'Pulp Mills',
  ['32212']: 'Paper Mills',
  ['32213']: 'Paperboard Mills',
  ['3222']: 'Converted Paper Product Manufacturing',
  ['32221']: 'Paperboard Container Manufacturing',
  ['32222']: 'Paper Bag and Coated and Treated Paper Manufacturing',
  ['32223']: 'Stationery Product Manufacturing',
  ['32229']: 'Other Converted Paper Product Manufacturing',
  ['323']: 'Printing and Related Support Activities',
  ['3231']: 'Printing and Related Support Activities',
  ['32311']: 'Printing',
  ['32312']: 'Support Activities for Printing',
  ['324']: 'Petroleum and Coal Products Manufacturing',
  ['3241']: 'Petroleum and Coal Products Manufacturing',
  ['32411']: 'Petroleum Refineries',
  ['32412']: 'Asphalt Paving, Roofing, and Saturated Materials Manufacturing',
  ['32419']: 'Other Petroleum and Coal Products Manufacturing',
  ['325']: 'Chemical Manufacturing',
  ['3251']: 'Basic Chemical Manufacturing',
  ['32511']: 'Petrochemical Manufacturing',
  ['32512']: 'Industrial Gas Manufacturing',
  ['32513']: 'Synthetic Dye and Pigment Manufacturing',
  ['32518']: 'Other Basic Inorganic Chemical Manufacturing',
  ['32519']: 'Other Basic Organic Chemical Manufacturing',
  ['3252']:
    'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
  ['32521']: 'Resin and Synthetic Rubber Manufacturing',
  ['32522']: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
  ['3253']:
    'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
  ['32531']: 'Fertilizer and Compost Manufacturing',
  ['32532']: 'Pesticide and Other Agricultural Chemical Manufacturing',
  ['3254']: 'Pharmaceutical and Medicine Manufacturing',
  ['32541']: 'Pharmaceutical and Medicine Manufacturing',
  ['3255']: 'Paint, Coating, and Adhesive Manufacturing',
  ['32551']: 'Paint and Coating Manufacturing',
  ['32552']: 'Adhesive Manufacturing',
  ['3256']: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
  ['32561']: 'Soap and Cleaning Compound Manufacturing',
  ['32562']: 'Toilet Preparation Manufacturing',
  ['3259']: 'Other Chemical Product and Preparation Manufacturing',
  ['32591']: 'Printing Ink Manufacturing',
  ['32592']: 'Explosives Manufacturing',
  ['32599']: 'All Other Chemical Product and Preparation Manufacturing',
  ['326']: 'Plastics and Rubber Products Manufacturing',
  ['3261']: 'Plastics Product Manufacturing',
  ['32611']:
    'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing',
  ['32612']:
    'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing',
  ['32613']:
    'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
  ['32614']: 'Polystyrene Foam Product Manufacturing',
  ['32615']:
    'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
  ['32616']: 'Plastics Bottle Manufacturing',
  ['32619']: 'Other Plastics Product Manufacturing',
  ['3262']: 'Rubber Product Manufacturing',
  ['32621']: 'Tire Manufacturing',
  ['32622']: 'Rubber and Plastics Hoses and Belting Manufacturing',
  ['32629']: 'Other Rubber Product Manufacturing',
  ['327']: 'Nonmetallic Mineral Product Manufacturing',
  ['3271']: 'Clay Product and Refractory Manufacturing',
  ['32711']: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
  ['32712']: 'Clay Building Material and Refractories Manufacturing',
  ['3272']: 'Glass and Glass Product Manufacturing',
  ['32721']: 'Glass and Glass Product Manufacturing',
  ['3273']: 'Cement and Concrete Product Manufacturing',
  ['32731']: 'Cement Manufacturing',
  ['32732']: 'Ready-Mix Concrete Manufacturing',
  ['32733']: 'Concrete Pipe, Brick, and Block Manufacturing',
  ['32739']: 'Other Concrete Product Manufacturing',
  ['3274']: 'Lime and Gypsum Product Manufacturing',
  ['32741']: 'Lime Manufacturing',
  ['32742']: 'Gypsum Product Manufacturing',
  ['3279']: 'Other Nonmetallic Mineral Product Manufacturing',
  ['32791']: 'Abrasive Product Manufacturing',
  ['32799']: 'All Other Nonmetallic Mineral Product Manufacturing',
  ['33']: 'Manufacturing',
  ['331']: 'Primary Metal Manufacturing',
  ['3311']: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  ['33111']: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  ['3312']: 'Steel Product Manufacturing from Purchased Steel',
  ['33121']: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
  ['33122']: 'Rolling and Drawing of Purchased Steel',
  ['3313']: 'Alumina and Aluminum Production and Processing',
  ['33131']: 'Alumina and Aluminum Production and Processing',
  ['331314']: 'Secondary Smelting and Alloying of Aluminum',
  ['3314']: 'Nonferrous Metal (except Aluminum) Production and Processing',
  ['33141']: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
  ['33142']: 'Copper Rolling, Drawing, Extruding, and Alloying',
  ['33149']:
    'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying',
  ['3315']: 'Foundries',
  ['33151']: 'Ferrous Metal Foundries',
  ['33152']: 'Nonferrous Metal Foundries',
  ['332']: 'Fabricated Metal Product Manufacturing',
  ['3321']: 'Forging and Stamping',
  ['33211']: 'Forging and Stamping',
  ['3322']: 'Cutlery and Handtool Manufacturing',
  ['33221']: 'Cutlery and Handtool Manufacturing',
  ['3323']: 'Architectural and Structural Metals Manufacturing',
  ['33231']: 'Plate Work and Fabricated Structural Product Manufacturing',
  ['33232']: 'Ornamental and Architectural Metal Products Manufacturing',
  ['3324']: 'Boiler, Tank, and Shipping Container Manufacturing',
  ['33241']: 'Power Boiler and Heat Exchanger Manufacturing',
  ['33242']: 'Metal Tank (Heavy Gauge) Manufacturing',
  ['33243']:
    'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing',
  ['3325']: 'Hardware Manufacturing',
  ['33251']: 'Hardware Manufacturing',
  ['3326']: 'Spring and Wire Product Manufacturing',
  ['33261']: 'Spring and Wire Product Manufacturing',
  ['3327']:
    'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
  ['33271']: 'Machine Shops',
  ['33272']: 'Turned Product and Screw, Nut, and Bolt Manufacturing',
  ['3328']: 'Coating, Engraving, Heat Treating, and Allied Activities',
  ['33281']: 'Coating, Engraving, Heat Treating, and Allied Activities',
  ['3329']: 'Other Fabricated Metal Product Manufacturing',
  ['33291']: 'Metal Valve Manufacturing',
  ['33299']: 'All Other Fabricated Metal Product Manufacturing',
  ['333']: 'Machinery Manufacturing',
  ['3331']: 'Agriculture, Construction, and Mining Machinery Manufacturing',
  ['33311']: 'Agricultural Implement Manufacturing',
  ['33312']: 'Construction Machinery Manufacturing',
  ['33313']: 'Mining and Oil and Gas Field Machinery Manufacturing',
  ['3332']: 'Industrial Machinery Manufacturing',
  ['33324']: 'Industrial Machinery Manufacturing',
  ['3333']: 'Commercial and Service Industry Machinery Manufacturing',
  ['33331']: 'Commercial and Service Industry Machinery Manufacturing',
  ['3334']:
    'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
  ['33341']:
    'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
  ['3335']: 'Metalworking Machinery Manufacturing',
  ['33351']: 'Metalworking Machinery Manufacturing',
  ['3336']: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
  ['33361']: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
  ['3339']: 'Other General Purpose Machinery Manufacturing',
  ['33391']: 'Pump and Compressor Manufacturing',
  ['33392']: 'Material Handling Equipment Manufacturing',
  ['33399']: 'All Other General Purpose Machinery Manufacturing',
  ['334']: 'Computer and Electronic Product Manufacturing',
  ['3341']: 'Computer and Peripheral Equipment Manufacturing',
  ['33411']: 'Computer and Peripheral Equipment Manufacturing',
  ['3342']: 'Communications Equipment Manufacturing',
  ['33421']: 'Telephone Apparatus Manufacturing',
  ['33422']:
    'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
  ['33429']: 'Other Communications Equipment Manufacturing',
  ['3343']: 'Audio and Video Equipment Manufacturing',
  ['33431']: 'Audio and Video Equipment Manufacturing',
  ['3344']: 'Semiconductor and Other Electronic Component Manufacturing',
  ['33441']: 'Semiconductor and Other Electronic Component Manufacturing',
  ['3345']:
    'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
  ['33451']:
    'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
  ['3346']: 'Manufacturing and Reproducing Magnetic and Optical Media',
  ['33461']: 'Manufacturing and Reproducing Magnetic and Optical Media',
  ['335']: 'Electrical Equipment, Appliance, and Component Manufacturing',
  ['3351']: 'Electric Lighting Equipment Manufacturing',
  ['33513']: 'Electric Lighting Equipment Manufacturing',
  ['3352']: 'Household Appliance Manufacturing',
  ['33521']: 'Small Electrical Appliance Manufacturing',
  ['33522']: 'Major Household Appliance Manufacturing',
  ['3353']: 'Electrical Equipment Manufacturing',
  ['33531']: 'Electrical Equipment Manufacturing',
  ['3359']: 'Other Electrical Equipment and Component Manufacturing',
  ['33591']: 'Battery Manufacturing',
  ['33592']: 'Communication and Energy Wire and Cable Manufacturing',
  ['33593']: 'Wiring Device Manufacturing',
  ['33599']: 'All Other Electrical Equipment and Component Manufacturing',
  ['336']: 'Transportation Equipment Manufacturing',
  ['3361']: 'Motor Vehicle Manufacturing',
  ['33611']: 'Automobile and Light Duty Motor Vehicle Manufacturing',
  ['33612']: 'Heavy Duty Truck Manufacturing',
  ['3362']: 'Motor Vehicle Body and Trailer Manufacturing',
  ['33621']: 'Motor Vehicle Body and Trailer Manufacturing',
  ['3363']: 'Motor Vehicle Parts Manufacturing',
  ['33631']: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
  ['33632']: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
  ['33633']:
    'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
  ['33634']: 'Motor Vehicle Brake System Manufacturing',
  ['33635']: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
  ['33636']: 'Motor Vehicle Seating and Interior Trim Manufacturing',
  ['33637']: 'Motor Vehicle Metal Stamping',
  ['33639']: 'Other Motor Vehicle Parts Manufacturing',
  ['3364']: 'Aerospace Product and Parts Manufacturing',
  ['33641']: 'Aerospace Product and Parts Manufacturing',
  ['3365']: 'Railroad Rolling Stock Manufacturing',
  ['33651']: 'Railroad Rolling Stock Manufacturing',
  ['3366']: 'Ship and Boat Building',
  ['33661']: 'Ship and Boat Building',
  ['3369']: 'Other Transportation Equipment Manufacturing',
  ['33699']: 'Other Transportation Equipment Manufacturing',
  ['337']: 'Furniture and Related Product Manufacturing',
  ['3371']:
    'Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
  ['33711']: 'Wood Kitchen Cabinet and Countertop Manufacturing',
  ['33712']: 'Household and Institutional Furniture Manufacturing',
  ['3372']: 'Office Furniture (including Fixtures) Manufacturing',
  ['33721']: 'Office Furniture (including Fixtures) Manufacturing',
  ['3379']: 'Other Furniture Related Product Manufacturing',
  ['33791']: 'Mattress Manufacturing',
  ['33792']: 'Blind and Shade Manufacturing',
  ['339']: 'Miscellaneous Manufacturing',
  ['3391']: 'Medical Equipment and Supplies Manufacturing',
  ['33911']: 'Medical Equipment and Supplies Manufacturing',
  ['3399']: 'Other Miscellaneous Manufacturing',
  ['33991']: 'Jewelry and Silverware Manufacturing',
  ['33992']: 'Sporting and Athletic Goods Manufacturing',
  ['33993']: 'Doll, Toy, and Game Manufacturing',
  ['33994']: 'Office Supplies (except Paper) Manufacturing',
  ['33995']: 'Sign Manufacturing',
  ['33999']: 'All Other Miscellaneous Manufacturing',
  ['42']: 'Wholesale Trade',
  ['423']: 'Merchant Wholesalers, Durable Goods',
  ['4231']:
    'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
  ['42311']: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
  ['42312']: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  ['42313']: 'Tire and Tube Merchant Wholesalers',
  ['42314']: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
  ['4232']: 'Furniture and Home Furnishing Merchant Wholesalers',
  ['42321']: 'Furniture Merchant Wholesalers',
  ['42322']: 'Home Furnishing Merchant Wholesalers',
  ['4233']: 'Lumber and Other Construction Materials Merchant Wholesalers',
  ['42331']: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  ['42332']:
    'Brick, Stone, and Related Construction Material Merchant Wholesalers',
  ['42333']: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
  ['42339']: 'Other Construction Material Merchant Wholesalers',
  ['4234']:
    'Professional and Commercial Equipment and Supplies Merchant Wholesalers',
  ['42341']: 'Photographic Equipment and Supplies Merchant Wholesalers',
  ['42342']: 'Office Equipment Merchant Wholesalers',
  ['42343']:
    'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
  ['42344']: 'Other Commercial Equipment Merchant Wholesalers',
  ['42345']:
    'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
  ['42346']: 'Ophthalmic Goods Merchant Wholesalers',
  ['42349']: 'Other Professional Equipment and Supplies Merchant Wholesalers',
  ['4235']: 'Metal and Mineral (except Petroleum) Merchant Wholesalers',
  ['42351']: 'Metal Service Centers and Other Metal Merchant Wholesalers',
  ['42352']: 'Coal and Other Mineral and Ore Merchant Wholesalers',
  ['4236']:
    'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
  ['42361']:
    'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
  ['42362']:
    'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
  ['42369']: 'Other Electronic Parts and Equipment Merchant Wholesalers',
  ['4237']:
    'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
  ['42371']: 'Hardware Merchant Wholesalers',
  ['42372']:
    'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  ['42373']:
    'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  ['42374']: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
  ['4238']: 'Machinery, Equipment, and Supplies Merchant Wholesalers',
  ['42381']:
    'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
  ['42382']: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  ['42383']: 'Industrial Machinery and Equipment Merchant Wholesalers',
  ['42384']: 'Industrial Supplies Merchant Wholesalers',
  ['42385']:
    'Service Establishment Equipment and Supplies Merchant Wholesalers',
  ['42386']:
    'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
  ['4239']: 'Miscellaneous Durable Goods Merchant Wholesalers',
  ['42391']:
    'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  ['42392']: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  ['42393']: 'Recyclable Material Merchant Wholesalers',
  ['42394']:
    'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
  ['42399']: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
  ['424']: 'Merchant Wholesalers, Nondurable Goods',
  ['4241']: 'Paper and Paper Product Merchant Wholesalers',
  ['42411']: 'Printing and Writing Paper Merchant Wholesalers',
  ['42412']: 'Stationery and Office Supplies Merchant Wholesalers',
  ['42413']: 'Industrial and Personal Service Paper Merchant Wholesalers',
  ['4242']: "Drugs and Druggists' Sundries Merchant Wholesalers",
  ['42421']: "Drugs and Druggists' Sundries Merchant Wholesalers",
  ['4243']: 'Apparel, Piece Goods, and Notions Merchant Wholesalers',
  ['42431']: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
  ['42434']: 'Footwear Merchant Wholesalers',
  ['42435']: 'Clothing and Clothing Accessories Merchant Wholesalers',
  ['4244']: 'Grocery and Related Product Merchant Wholesalers',
  ['42441']: 'General Line Grocery Merchant Wholesalers',
  ['42442']: 'Packaged Frozen Food Merchant Wholesalers',
  ['42443']: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
  ['42444']: 'Poultry and Poultry Product Merchant Wholesalers',
  ['42445']: 'Confectionery Merchant Wholesalers',
  ['42446']: 'Fish and Seafood Merchant Wholesalers',
  ['42447']: 'Meat and Meat Product Merchant Wholesalers',
  ['42448']: 'Fresh Fruit and Vegetable Merchant Wholesalers',
  ['42449']: 'Other Grocery and Related Products Merchant Wholesalers',
  ['4245']: 'Farm Product Raw Material Merchant Wholesalers',
  ['42451']: 'Grain and Field Bean Merchant Wholesalers',
  ['42452']: 'Livestock Merchant Wholesalers',
  ['42459']: 'Other Farm Product Raw Material Merchant Wholesalers',
  ['4246']: 'Chemical and Allied Products Merchant Wholesalers',
  ['42461']:
    'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  ['42469']: 'Other Chemical and Allied Products Merchant Wholesalers',
  ['4247']: 'Petroleum and Petroleum Products Merchant Wholesalers',
  ['42471']: 'Petroleum Bulk Stations and Terminals',
  ['42472']:
    'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
  ['4248']: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
  ['42481']: 'Beer and Ale Merchant Wholesalers',
  ['42482']: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  ['4249']: 'Miscellaneous Nondurable Goods Merchant Wholesalers',
  ['42491']: 'Farm Supplies Merchant Wholesalers',
  ['42492']: 'Book, Periodical, and Newspaper Merchant Wholesalers',
  ['42493']:
    "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
  ['42494']: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
  ['42495']: 'Paint, Varnish, and Supplies Merchant Wholesalers',
  ['42499']: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
  ['425']: 'Wholesale Trade Agents and Brokers',
  ['4251']: 'Wholesale Trade Agents and Brokers',
  ['42512']: 'Wholesale Trade Agents and Brokers',
  ['44']: 'Retail Trade',
  ['441']: 'Motor Vehicle and Parts Dealers',
  ['4411']: 'Automobile Dealers',
  ['44111']: 'New Car Dealers',
  ['44112']: 'Used Car Dealers',
  ['4412']: 'Other Motor Vehicle Dealers',
  ['44121']: 'Recreational Vehicle Dealers',
  ['44122']: 'Motorcycle, Boat, and Other Motor Vehicle Dealers',
  ['4413']: 'Automotive Parts, Accessories, and Tire Retailers',
  ['44133']: 'Automotive Parts and Accessories Retailers',
  ['44134']: 'Tire Dealers',
  ['444']: 'Building Material and Garden Equipment and Supplies Dealers',
  ['4441']: 'Building Material and Supplies Dealers',
  ['44411']: 'Home Centers',
  ['44412']: 'Paint and Wallpaper Retailers',
  ['44414']: 'Hardware Retailers',
  ['44418']: 'Other Building Material Dealers',
  ['4442']: 'Lawn and Garden Equipment and Supplies Retailers',
  ['44423']: 'Outdoor Power Equipment Retailers',
  ['44424']: 'Nursery, Garden Center, and Farm Supply Retailers',
  ['445']: 'Food and Beverage Retailers',
  ['4451']: 'Grocery and Convenience Retailers',
  ['44511']:
    'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
  ['44513']: 'Convenience Retailers and Vending Machine Operators',
  ['4452']: 'Specialty Food Retailers',
  ['44523']: 'Fruit and Vegetable Retailers',
  ['44524']: 'Meat Retailers',
  ['44525']: 'Fish and Seafood Retailers',
  ['44529']: 'Other Specialty Food Retailers',
  ['4453']: 'Beer, Wine, and Liquor Retailers',
  ['44532']: 'Beer, Wine, and Liquor Retailers',
  ['449']: 'Furniture, Home Furnishings, Electronics, and Appliance Retailers',
  ['4491']: 'Furniture and Home Furnishings Retailers',
  ['44911']: 'Furniture Retailers',
  ['44912']: 'Home Furnishings Retailers',
  ['4492']: 'Electronics and Appliance Retailers',
  ['44921']: 'Electronics and Appliance Retailers',
  ['45']: 'Retail Trade',
  ['455']: 'General Merchandise Retailers',
  ['4551']: 'Department Stores',
  ['45511']: 'Department Stores',
  ['4552']:
    'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
  ['45521']:
    'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
  ['456']: 'Health and Personal Care Retailers',
  ['4561']: 'Health and Personal Care Retailers',
  ['45611']: 'Pharmacies and Drug Retailers',
  ['45612']: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
  ['45613']: 'Optical Goods Retailers',
  ['45619']: 'Other Health and Personal Care Retailers',
  ['457']: 'Gasoline Stations and Fuel Dealers',
  ['4571']: 'Gasoline Stations',
  ['45711']: 'Gasoline Stations with Convenience Stores',
  ['45712']: 'Other Gasoline Stations',
  ['4572']: 'Fuel Dealers',
  ['45721']: 'Fuel Dealers',
  ['458']: 'Clothing, Clothing Accessories, Shoe, and Jewelry Retailers',
  ['4581']: 'Clothing and Clothing Accessories Retailers',
  ['45811']: 'Clothing and Clothing Accessories Retailers',
  ['4582']: 'Shoe Retailers',
  ['45821']: 'Shoe Retailers',
  ['4583']: 'Jewelry, Luggage, and Leather Goods Retailers',
  ['45831']: 'Jewelry Retailers',
  ['45832']: 'Luggage and Leather Goods Retailers',
  ['459']:
    'Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers',
  ['4591']: 'Sporting Goods, Hobby, and Musical Instrument Retailers',
  ['45911']: 'Sporting Goods Retailers',
  ['45912']: 'Hobby, Toy, and Game Retailers',
  ['45913']: 'Sewing, Needlework, and Piece Goods Retailers',
  ['45914']: 'Musical Instrument and Supplies Retailers',
  ['4592']: 'Book Retailers and News Dealers',
  ['45921']: 'Book Retailers and News Dealers',
  ['4593']: 'Florists',
  ['45931']: 'Florists',
  ['4594']: 'Office Supplies, Stationery, and Gift Retailers',
  ['45941']: 'Office Supplies and Stationery Retailers',
  ['45942']: 'Gift, Novelty, and Souvenir Retailers',
  ['4595']: 'Used Merchandise Retailers',
  ['45951']: 'Used Merchandise Retailers',
  ['4599']: 'Other Miscellaneous Retailers',
  ['45991']: 'Pet and Pet Supplies Retailers',
  ['45992']: 'Art Dealers',
  ['45993']: 'Manufactured (Mobile) Home Dealers',
  ['45999']: 'All Other Miscellaneous Retailers',
  ['48']: 'Transportation and Warehousing',
  ['481']: 'Air Transportation',
  ['4811']: 'Scheduled Air Transportation',
  ['48111']: 'Scheduled Air Transportation',
  ['4812']: 'Nonscheduled Air Transportation',
  ['48121']: 'Nonscheduled Air Transportation',
  ['482']: 'Rail Transportation',
  ['4821']: 'Rail Transportation',
  ['48211']: 'Rail Transportation',
  ['483']: 'Water Transportation',
  ['4831']: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
  ['48311']: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
  ['4832']: 'Inland Water Transportation',
  ['48321']: 'Inland Water Transportation',
  ['484']: 'Truck Transportation',
  ['4841']: 'General Freight Trucking',
  ['48411']: 'General Freight Trucking, Local',
  ['48412']: 'General Freight Trucking, Long-Distance',
  ['4842']: 'Specialized Freight Trucking',
  ['48421']: 'Used Household and Office Goods Moving',
  ['48422']: 'Specialized Freight (except Used Goods) Trucking, Local',
  ['48423']: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
  ['485']: 'Transit and Ground Passenger Transportation',
  ['4851']: 'Urban Transit Systems',
  ['48511']: 'Urban Transit Systems',
  ['4852']: 'Interurban and Rural Bus Transportation',
  ['48521']: 'Interurban and Rural Bus Transportation',
  ['4853']: 'Taxi and Limousine Service',
  ['48531']: 'Taxi and Ridesharing Services',
  ['48532']: 'Limousine Service',
  ['4854']: 'School and Employee Bus Transportation',
  ['48541']: 'School and Employee Bus Transportation',
  ['4855']: 'Charter Bus Industry',
  ['48551']: 'Charter Bus Industry',
  ['4859']: 'Other Transit and Ground Passenger Transportation',
  ['48599']: 'Other Transit and Ground Passenger Transportation',
  ['486']: 'Pipeline Transportation',
  ['4861']: 'Pipeline Transportation of Crude Oil',
  ['48611']: 'Pipeline Transportation of Crude Oil',
  ['4862']: 'Pipeline Transportation of Natural Gas',
  ['48621']: 'Pipeline Transportation of Natural Gas',
  ['4869']: 'Other Pipeline Transportation',
  ['48691']: 'Pipeline Transportation of Refined Petroleum Products',
  ['48699']: 'All Other Pipeline Transportation',
  ['487']: 'Scenic and Sightseeing Transportation',
  ['4871']: 'Scenic and Sightseeing Transportation, Land',
  ['48711']: 'Scenic and Sightseeing Transportation, Land',
  ['4872']: 'Scenic and Sightseeing Transportation, Water',
  ['48721']: 'Scenic and Sightseeing Transportation, Water',
  ['4879']: 'Scenic and Sightseeing Transportation, Other',
  ['48799']: 'Scenic and Sightseeing Transportation, Other',
  ['488']: 'Support Activities for Transportation',
  ['4881']: 'Support Activities for Air Transportation',
  ['48811']: 'Airport Operations',
  ['48819']: 'Other Support Activities for Air Transportation',
  ['4882']: 'Support Activities for Rail Transportation',
  ['48821']: 'Support Activities for Rail Transportation',
  ['4883']: 'Support Activities for Water Transportation',
  ['48831']: 'Port and Harbor Operations',
  ['48832']: 'Marine Cargo Handling',
  ['48833']: 'Navigational Services to Shipping',
  ['48839']: 'Other Support Activities for Water Transportation',
  ['4884']: 'Support Activities for Road Transportation',
  ['48841']: 'Motor Vehicle Towing',
  ['48849']: 'Other Support Activities for Road Transportation',
  ['4885']: 'Freight Transportation Arrangement',
  ['48851']: 'Freight Transportation Arrangement',
  ['4889']: 'Other Support Activities for Transportation',
  ['48899']: 'Other Support Activities for Transportation',
  ['49']: 'Transportation and Warehousing',
  ['491']: 'Postal Service',
  ['4911']: 'Postal Service',
  ['49111']: 'Postal Service',
  ['492']: 'Couriers and Messengers',
  ['4921']: 'Couriers and Express Delivery Services',
  ['49211']: 'Couriers and Express Delivery Services',
  ['4922']: 'Local Messengers and Local Delivery',
  ['49221']: 'Local Messengers and Local Delivery',
  ['493']: 'Warehousing and Storage',
  ['4931']: 'Warehousing and Storage',
  ['49311']: 'General Warehousing and Storage',
  ['49312']: 'Refrigerated Warehousing and Storage',
  ['49313']: 'Farm Product Warehousing and Storage',
  ['49319']: 'Other Warehousing and Storage',
  ['51']: 'Information',
  ['512']: 'Motion Picture and Sound Recording Industries',
  ['5121']: 'Motion Picture and Video Industries',
  ['51211']: 'Motion Picture and Video Production',
  ['51212']: 'Motion Picture and Video Distribution',
  ['51213']: 'Motion Picture and Video Exhibition',
  ['51219']:
    'Postproduction Services and Other Motion Picture and Video Industries',
  ['5122']: 'Sound Recording Industries',
  ['51223']: 'Music Publishers',
  ['51224']: 'Sound Recording Studios',
  ['51225']: 'Record Production and Distribution',
  ['51229']: 'Other Sound Recording Industries',
  ['513']: 'Publishing Industries',
  ['5131']: 'Newspaper, Periodical, Book, and Directory Publishers',
  ['51311']: 'Newspaper Publishers',
  ['51312']: 'Periodical Publishers',
  ['51313']: 'Book Publishers',
  ['51314']: 'Directory and Mailing List Publishers',
  ['51319']: 'Other Publishers',
  ['5132']: 'Software Publishers',
  ['51321']: 'Software Publishers',
  ['516']: 'Broadcasting and Content Providers',
  ['5161']: 'Radio and Television Broadcasting Stations',
  ['51611']: 'Radio Broadcasting Stations',
  ['51612']: 'Television Broadcasting Stations',
  ['5162']:
    'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  ['51621']:
    'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  ['517']: 'Telecommunications',
  ['5171']: 'Wired and Wireless Telecommunications (except Satellite)',
  ['51711']:
    'Wired and Wireless Telecommunications Carriers (except Satellite)',
  ['51712']:
    'Telecommunications Resellers and Agents for Wireless Telecommunication Services',
  ['5174']: 'Satellite Telecommunications',
  ['51741']: 'Satellite Telecommunications',
  ['5178']: 'All Other Telecommunications',
  ['51781']: 'All Other Telecommunications',
  ['518']:
    'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  ['5182']:
    'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  ['51821']:
    'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
  ['519']:
    'Web Search Portals, Libraries, Archives, and Other Information Services',
  ['5192']:
    'Web Search Portals, Libraries, Archives, and Other Information Services',
  ['51921']: 'Libraries and Archives',
  ['51929']: 'Web Search Portals and All Other Information Services',
  ['52']: 'Finance and Insurance',
  ['521']: 'Monetary Authorities-Central Bank',
  ['5211']: 'Monetary Authorities-Central Bank',
  ['52111']: 'Monetary Authorities-Central Bank',
  ['522']: 'Credit Intermediation and Related Activities',
  ['5221']: 'Depository Credit Intermediation',
  ['52211']: 'Commercial Banking',
  ['52213']: 'Credit Unions',
  ['52218']: 'Savings Institutions and Other Depository Credit Intermediation',
  ['5222']: 'Nondepository Credit Intermediation',
  ['52221']: 'Credit Card Issuing',
  ['52222']: 'Sales Financing',
  ['52229']: 'Other Nondepository Credit Intermediation',
  ['5223']: 'Activities Related to Credit Intermediation',
  ['52231']: 'Mortgage and Nonmortgage Loan Brokers',
  ['52232']:
    'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
  ['52239']: 'Other Activities Related to Credit Intermediation',
  ['523']:
    'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
  ['5231']: 'Securities and Commodity Contracts Intermediation and Brokerage',
  ['52315']: 'Investment Banking and Securities Intermediation',
  ['52316']: 'Commodity Contracts Intermediation',
  ['5232']: 'Securities and Commodity Exchanges',
  ['52321']: 'Securities and Commodity Exchanges',
  ['5239']: 'Other Financial Investment Activities',
  ['52391']: 'Miscellaneous Intermediation',
  ['52394']: 'Portfolio Management and Investment Advice',
  ['52399']: 'All Other Financial Investment Activities',
  ['524']: 'Insurance Carriers and Related Activities',
  ['5241']: 'Insurance Carriers',
  ['52411']: 'Direct Life, Health, and Medical Insurance Carriers',
  ['52412']: 'Direct Insurance (except Life, Health, and Medical) Carriers',
  ['52413']: 'Reinsurance Carriers',
  ['5242']: 'Agencies, Brokerages, and Other Insurance Related Activities',
  ['52421']: 'Insurance Agencies and Brokerages',
  ['52429']: 'Other Insurance Related Activities',
  ['525']: 'Funds, Trusts, and Other Financial Vehicles',
  ['5251']: 'Insurance and Employee Benefit Funds',
  ['52511']: 'Pension Funds',
  ['52512']: 'Health and Welfare Funds',
  ['52519']: 'Other Insurance Funds',
  ['5259']: 'Other Investment Pools and Funds',
  ['52591']: 'Open-End Investment Funds',
  ['52592']: 'Trusts, Estates, and Agency Accounts',
  ['52599']: 'Other Financial Vehicles',
  ['53']: 'Real Estate and Rental and Leasing',
  ['531']: 'Real Estate',
  ['5311']: 'Lessors of Real Estate',
  ['53111']: 'Lessors of Residential Buildings and Dwellings',
  ['53112']: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
  ['53113']: 'Lessors of Miniwarehouses and Self-Storage Units',
  ['53119']: 'Lessors of Other Real Estate Property',
  ['5312']: 'Offices of Real Estate Agents and Brokers',
  ['53121']: 'Offices of Real Estate Agents and Brokers',
  ['5313']: 'Activities Related to Real Estate',
  ['53131']: 'Real Estate Property Managers',
  ['53132']: 'Offices of Real Estate Appraisers',
  ['53139']: 'Other Activities Related to Real Estate',
  ['532']: 'Rental and Leasing Services',
  ['5321']: 'Automotive Equipment Rental and Leasing',
  ['53211']: 'Passenger Car Rental and Leasing',
  ['53212']:
    'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  ['5322']: 'Consumer Goods Rental',
  ['53221']: 'Consumer Electronics and Appliances Rental',
  ['53228']: 'Other Consumer Goods Rental',
  ['5323']: 'General Rental Centers',
  ['53231']: 'General Rental Centers',
  ['5324']:
    'Commercial and Industrial Machinery and Equipment Rental and Leasing',
  ['53241']:
    'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing',
  ['53242']: 'Office Machinery and Equipment Rental and Leasing',
  ['53249']:
    'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  ['533']:
    'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  ['5331']:
    'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  ['53311']:
    'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  ['54']: 'Professional, Scientific, and Technical Services',
  ['541']: 'Professional, Scientific, and Technical Services',
  ['5411']: 'Legal Services',
  ['54111']: 'Offices of Lawyers',
  ['54112']: 'Offices of Notaries',
  ['54119']: 'Other Legal Services',
  ['5412']: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
  ['54121']: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
  ['5413']: 'Architectural, Engineering, and Related Services',
  ['54131']: 'Architectural Services',
  ['54132']: 'Landscape Architectural Services',
  ['54133']: 'Engineering Services',
  ['54134']: 'Drafting Services',
  ['54135']: 'Building Inspection Services',
  ['54136']: 'Geophysical Surveying and Mapping Services',
  ['54137']: 'Surveying and Mapping (except Geophysical) Services',
  ['54138']: 'Testing Laboratories and Services',
  ['5414']: 'Specialized Design Services',
  ['54141']: 'Interior Design Services',
  ['54142']: 'Industrial Design Services',
  ['54143']: 'Graphic Design Services',
  ['54149']: 'Other Specialized Design Services',
  ['5415']: 'Computer Systems Design and Related Services',
  ['54151']: 'Computer Systems Design and Related Services',
  ['5416']: 'Management, Scientific, and Technical Consulting Services',
  ['54161']: 'Management Consulting Services',
  ['54162']: 'Environmental Consulting Services',
  ['54169']: 'Other Scientific and Technical Consulting Services',
  ['5417']: 'Scientific Research and Development Services',
  ['54171']:
    'Research and Development in the Physical, Engineering, and Life Sciences',
  ['54172']: 'Research and Development in the Social Sciences and Humanities',
  ['5418']: 'Advertising, Public Relations, and Related Services',
  ['54181']: 'Advertising Agencies',
  ['54182']: 'Public Relations Agencies',
  ['54183']: 'Media Buying Agencies',
  ['54184']: 'Media Representatives',
  ['54185']: 'Indoor and Outdoor Display Advertising',
  ['54186']: 'Direct Mail Advertising',
  ['54187']: 'Advertising Material Distribution Services',
  ['54189']: 'Other Services Related to Advertising',
  ['5419']: 'Other Professional, Scientific, and Technical Services',
  ['54191']: 'Marketing Research and Public Opinion Polling',
  ['54192']: 'Photographic Services',
  ['54193']: 'Translation and Interpretation Services',
  ['54194']: 'Veterinary Services',
  ['54199']: 'All Other Professional, Scientific, and Technical Services',
  ['55']: 'Management of Companies and Enterprises',
  ['551']: 'Management of Companies and Enterprises',
  ['5511']: 'Management of Companies and Enterprises',
  ['55111']: 'Management of Companies and Enterprises',
  ['56']:
    'Administrative and Support and Waste Management and Remediation Services',
  ['561']: 'Administrative and Support Services',
  ['5611']: 'Office Administrative Services',
  ['56111']: 'Office Administrative Services',
  ['5612']: 'Facilities Support Services',
  ['56121']: 'Facilities Support Services',
  ['5613']: 'Employment Services',
  ['56131']: 'Employment Placement Agencies and Executive Search Services',
  ['56132']: 'Temporary Help Services',
  ['56133']: 'Professional Employer Organizations',
  ['5614']: 'Business Support Services',
  ['56141']: 'Document Preparation Services',
  ['56142']: 'Telephone Call Centers',
  ['56143']: 'Business Service Centers',
  ['56144']: 'Collection Agencies',
  ['56145']: 'Credit Bureaus',
  ['56149']: 'Other Business Support Services',
  ['5615']: 'Travel Arrangement and Reservation Services',
  ['56151']: 'Travel Agencies',
  ['56152']: 'Tour Operators',
  ['56159']: 'Other Travel Arrangement and Reservation Services',
  ['5616']: 'Investigation and Security Services',
  ['56161']: 'Investigation, Guard, and Armored Car Services',
  ['56162']: 'Security Systems Services',
  ['5617']: 'Services to Buildings and Dwellings',
  ['56171']: 'Exterminating and Pest Control Services',
  ['56172']: 'Janitorial Services',
  ['56173']: 'Landscaping Services',
  ['56174']: 'Carpet and Upholstery Cleaning Services',
  ['56179']: 'Other Services to Buildings and Dwellings',
  ['5619']: 'Other Support Services',
  ['56191']: 'Packaging and Labeling Services',
  ['56192']: 'Convention and Trade Show Organizers',
  ['56199']: 'All Other Support Services',
  ['562']: 'Waste Management and Remediation Services',
  ['5621']: 'Waste Collection',
  ['56211']: 'Waste Collection',
  ['5622']: 'Waste Treatment and Disposal',
  ['56221']: 'Waste Treatment and Disposal',
  ['5629']: 'Remediation and Other Waste Management Services',
  ['56291']: 'Remediation Services',
  ['56292']: 'Materials Recovery Facilities',
  ['56299']: 'All Other Waste Management Services',
  ['61']: 'Educational Services',
  ['611']: 'Educational Services',
  ['6111']: 'Elementary and Secondary Schools',
  ['61111']: 'Elementary and Secondary Schools',
  ['6112']: 'Junior Colleges',
  ['61121']: 'Junior Colleges',
  ['6113']: 'Colleges, Universities, and Professional Schools',
  ['61131']: 'Colleges, Universities, and Professional Schools',
  ['6114']: 'Business Schools and Computer and Management Training',
  ['61141']: 'Business and Secretarial Schools',
  ['61142']: 'Computer Training',
  ['61143']: 'Professional and Management Development Training',
  ['6115']: 'Technical and Trade Schools',
  ['61151']: 'Technical and Trade Schools',
  ['6116']: 'Other Schools and Instruction',
  ['61161']: 'Fine Arts Schools',
  ['61162']: 'Sports and Recreation Instruction',
  ['61163']: 'Language Schools',
  ['61169']: 'All Other Schools and Instruction',
  ['6117']: 'Educational Support Services',
  ['61171']: 'Educational Support Services',
  ['62']: 'Health Care and Social Assistance',
  ['621']: 'Ambulatory Health Care Services',
  ['6211']: 'Offices of Physicians',
  ['62111']: 'Offices of Physicians',
  ['6212']: 'Offices of Dentists',
  ['62121']: 'Offices of Dentists',
  ['6213']: 'Offices of Other Health Practitioners',
  ['62131']: 'Offices of Chiropractors',
  ['62132']: 'Offices of Optometrists',
  ['62133']: 'Offices of Mental Health Practitioners (except Physicians)',
  ['62134']:
    'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
  ['62139']: 'Offices of All Other Health Practitioners',
  ['6214']: 'Outpatient Care Centers',
  ['62141']: 'Family Planning Centers',
  ['62142']: 'Outpatient Mental Health and Substance Abuse Centers',
  ['62149']: 'Other Outpatient Care Centers',
  ['6215']: 'Medical and Diagnostic Laboratories',
  ['62151']: 'Medical and Diagnostic Laboratories',
  ['6216']: 'Home Health Care Services',
  ['62161']: 'Home Health Care Services',
  ['6219']: 'Other Ambulatory Health Care Services',
  ['62191']: 'Ambulance Services',
  ['62199']: 'All Other Ambulatory Health Care Services',
  ['622']: 'Hospitals',
  ['6221']: 'General Medical and Surgical Hospitals',
  ['62211']: 'General Medical and Surgical Hospitals',
  ['6222']: 'Psychiatric and Substance Abuse Hospitals',
  ['62221']: 'Psychiatric and Substance Abuse Hospitals',
  ['6223']: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  ['62231']: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  ['623']: 'Nursing and Residential Care Facilities',
  ['6231']: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  ['62311']: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  ['6232']:
    'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
  ['62321']: 'Residential Intellectual and Developmental Disability Facilities',
  ['62322']: 'Residential Mental Health and Substance Abuse Facilities',
  ['6233']:
    'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
  ['62331']:
    'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
  ['6239']: 'Other Residential Care Facilities',
  ['62399']: 'Other Residential Care Facilities',
  ['624']: 'Social Assistance',
  ['6241']: 'Individual and Family Services',
  ['62411']: 'Child and Youth Services',
  ['62412']: 'Services for the Elderly and Persons with Disabilities',
  ['62419']: 'Other Individual and Family Services',
  ['6242']:
    'Community Food and Housing, and Emergency and Other Relief Services',
  ['62421']: 'Community Food Services',
  ['62422']: 'Community Housing Services',
  ['62423']: 'Emergency and Other Relief Services',
  ['6243']: 'Vocational Rehabilitation Services',
  ['62431']: 'Vocational Rehabilitation Services',
  ['6244']: 'Child Care Services',
  ['62441']: 'Child Care Services',
  ['71']: 'Arts, Entertainment, and Recreation',
  ['711']: 'Performing Arts, Spectator Sports, and Related Industries',
  ['7111']: 'Performing Arts Companies',
  ['71111']: 'Theater Companies and Dinner Theaters',
  ['71112']: 'Dance Companies',
  ['71113']: 'Musical Groups and Artists',
  ['71119']: 'Other Performing Arts Companies',
  ['7112']: 'Spectator Sports',
  ['71121']: 'Spectator Sports',
  ['7113']: 'Promoters of Performing Arts, Sports, and Similar Events',
  ['71131']:
    'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  ['71132']:
    'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
  ['7114']:
    'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  ['71141']:
    'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  ['7115']: 'Independent Artists, Writers, and Performers',
  ['71151']: 'Independent Artists, Writers, and Performers',
  ['712']: 'Museums, Historical Sites, and Similar Institutions',
  ['7121']: 'Museums, Historical Sites, and Similar Institutions',
  ['71211']: 'Museums',
  ['71212']: 'Historical Sites',
  ['71213']: 'Zoos and Botanical Gardens',
  ['71219']: 'Nature Parks and Other Similar Institutions',
  ['713']: 'Amusement, Gambling, and Recreation Industries',
  ['7131']: 'Amusement Parks and Arcades',
  ['71311']: 'Amusement and Theme Parks',
  ['71312']: 'Amusement Arcades',
  ['7132']: 'Gambling Industries',
  ['71321']: 'Casinos (except Casino Hotels)',
  ['71329']: 'Other Gambling Industries',
  ['7139']: 'Other Amusement and Recreation Industries',
  ['71391']: 'Golf Courses and Country Clubs',
  ['71392']: 'Skiing Facilities',
  ['71393']: 'Marinas',
  ['71394']: 'Fitness and Recreational Sports Centers',
  ['71395']: 'Bowling Centers',
  ['71399']: 'All Other Amusement and Recreation Industries',
  ['72']: 'Accommodation and Food Services',
  ['721']: 'Accommodation',
  ['7211']: 'Traveler Accommodation',
  ['72111']: 'Hotels (except Casino Hotels) and Motels',
  ['72112']: 'Casino Hotels',
  ['72119']: 'Other Traveler Accommodation',
  ['7212']: 'RV (Recreational Vehicle) Parks and Recreational Camps',
  ['72121']: 'RV (Recreational Vehicle) Parks and Recreational Camps',
  ['7213']: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
  ['72131']: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
  ['722']: 'Food Services and Drinking Places',
  ['7223']: 'Special Food Services',
  ['72231']: 'Food Service Contractors',
  ['72232']: 'Caterers',
  ['72233']: 'Mobile Food Services',
  ['7224']: 'Drinking Places (Alcoholic Beverages)',
  ['72241']: 'Drinking Places (Alcoholic Beverages)',
  ['7225']: 'Restaurants and Other Eating Places',
  ['72251']: 'Restaurants and Other Eating Places',
  ['81']: 'Other Services (except Public Administration)',
  ['811']: 'Repair and Maintenance',
  ['8111']: 'Automotive Repair and Maintenance',
  ['81111']: 'Automotive Mechanical and Electrical Repair and Maintenance',
  ['81112']: 'Automotive Body, Paint, Interior, and Glass Repair',
  ['81119']: 'Other Automotive Repair and Maintenance',
  ['8112']: 'Electronic and Precision Equipment Repair and Maintenance',
  ['81121']: 'Electronic and Precision Equipment Repair and Maintenance',
  ['8113']:
    'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  ['81131']:
    'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  ['8114']: 'Personal and Household Goods Repair and Maintenance',
  ['81141']: 'Home and Garden Equipment and Appliance Repair and Maintenance',
  ['81142']: 'Reupholstery and Furniture Repair',
  ['81143']: 'Footwear and Leather Goods Repair',
  ['81149']: 'Other Personal and Household Goods Repair and Maintenance',
  ['812']: 'Personal and Laundry Services',
  ['8121']: 'Personal Care Services',
  ['81211']: 'Hair, Nail, and Skin Care Services',
  ['81219']: 'Other Personal Care Services',
  ['8122']: 'Death Care Services',
  ['81221']: 'Funeral Homes and Funeral Services',
  ['81222']: 'Cemeteries and Crematories',
  ['8123']: 'Drycleaning and Laundry Services',
  ['81231']: 'Coin-Operated Laundries and Drycleaners',
  ['81232']: 'Drycleaning and Laundry Services (except Coin-Operated)',
  ['81233']: 'Linen and Uniform Supply',
  ['8129']: 'Other Personal Services',
  ['81291']: 'Pet Care (except Veterinary) Services',
  ['81292']: 'Photofinishing',
  ['81293']: 'Parking Lots and Garages',
  ['81299']: 'All Other Personal Services',
  ['813']:
    'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
  ['8131']: 'Religious Organizations',
  ['81311']: 'Religious Organizations',
  ['8132']: 'Grantmaking and Giving Services',
  ['81321']: 'Grantmaking and Giving Services',
  ['8133']: 'Social Advocacy Organizations',
  ['81331']: 'Social Advocacy Organizations',
  ['8134']: 'Civic and Social Organizations',
  ['81341']: 'Civic and Social Organizations',
  ['8139']:
    'Business, Professional, Labor, Political, and Similar Organizations',
  ['81391']: 'Business Associations',
  ['81392']: 'Professional Organizations',
  ['81393']: 'Labor Unions and Similar Labor Organizations',
  ['81394']: 'Political Organizations',
  ['81399']:
    'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
  ['814']: 'Private Households',
  ['8141']: 'Private Households',
  ['81411']: 'Private Households',
  ['92']: 'Public Administration',
  ['921']: 'Executive, Legislative, and Other General Government Support',
  ['9211']: 'Executive, Legislative, and Other General Government Support',
  ['92111']: 'Executive Offices',
  ['92112']: 'Legislative Bodies',
  ['92113']: 'Public Finance Activities',
  ['92114']: 'Executive and Legislative Offices, Combined',
  ['92115']: 'American Indian and Alaska Native Tribal Governments',
  ['92119']: 'Other General Government Support',
  ['922']: 'Justice, Public Order, and Safety Activities',
  ['9221']: 'Justice, Public Order, and Safety Activities',
  ['92211']: 'Courts',
  ['92212']: 'Police Protection',
  ['92213']: 'Legal Counsel and Prosecution',
  ['92214']: 'Correctional Institutions',
  ['92215']: 'Parole Offices and Probation Offices',
  ['92216']: 'Fire Protection',
  ['92219']: 'Other Justice, Public Order, and Safety Activities',
  ['923']: 'Administration of Human Resource Programs',
  ['9231']: 'Administration of Human Resource Programs',
  ['92311']: 'Administration of Education Programs',
  ['92312']: 'Administration of Public Health Programs',
  ['92313']:
    "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
  ['92314']: "Administration of Veterans' Affairs",
  ['924']: 'Administration of Environmental Quality Programs',
  ['9241']: 'Administration of Environmental Quality Programs',
  ['92411']:
    'Administration of Air and Water Resource and Solid Waste Management Programs',
  ['92412']: 'Administration of Conservation Programs',
  ['925']:
    'Administration of Housing Programs, Urban Planning, and Community Development',
  ['9251']:
    'Administration of Housing Programs, Urban Planning, and Community Development',
  ['92511']: 'Administration of Housing Programs',
  ['92512']:
    'Administration of Urban Planning and Community and Rural Development',
  ['926']: 'Administration of Economic Programs',
  ['9261']: 'Administration of Economic Programs',
  ['92611']: 'Administration of General Economic Programs',
  ['92612']: 'Regulation and Administration of Transportation Programs',
  ['92613']:
    'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
  ['92614']: 'Regulation of Agricultural Marketing and Commodities',
  ['92615']:
    'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
  ['927']: 'Space Research and Technology',
  ['9271']: 'Space Research and Technology',
  ['92711']: 'Space Research and Technology',
  ['928']: 'National Security and International Affairs',
  ['9281']: 'National Security and International Affairs',
  ['92811']: 'National Security',
  ['92812']: 'International Affairs',
  ['99']: 'Unclassified Establishments',
  ['999977']: 'Conglomerates',
  ['999988']: 'Multi-Sector Holdings',
  ['999990']: 'Non-Operating Establishments',
};
