import { ReactNode, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Formik } from 'formik';
import { GQCreatePermissionItemInput } from '@watershed/shared-universal/generated/graphql';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import { formatAddPermissionDescription } from '@watershed/shared-universal/permissions/AddUserPermissionsUtils';

import useLocale from '@watershed/intl/frontend/useLocale';

export default function ConfirmPermissionDialog({
  onConfirm,
  onCancel,

  permissionsOptions,
  defaultSelectedPermission = 0,
  children,
  shouldShowPermissions = true,
}: {
  onConfirm: (
    permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>
  ) => void;
  onCancel: () => void;
  permissionsOptions?: Array<
    Array<GQCreatePermissionItemInput & { objectName?: string }>
  >;
  defaultSelectedPermission?: number;
  children: ReactNode;
  shouldShowPermissions?: boolean;
}) {
  const locale = useLocale();
  const { t } = useLingui();
  const [selectedPermissionsIdx, setSelectedPermissionsIdx] = useState<
    number | undefined
  >(defaultSelectedPermission);

  const showPermissions =
    shouldShowPermissions &&
    !!permissionsOptions &&
    permissionsOptions.length > 0;

  function onSubmit() {
    const permissionsToAdd =
      selectedPermissionsIdx !== undefined &&
      permissionsOptions &&
      permissionsOptions[selectedPermissionsIdx];
    if (permissionsToAdd) {
      onConfirm(permissionsToAdd);
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={() => onSubmit()}>
      {() => (
        <DialogForm
          onClose={() => onCancel()}
          maxWidth="xs"
          header={{ title: <Trans>Grant permission?</Trans> }}
          submit={t`Grant permission`}
        >
          {children && <Typography variant="body2">{children}</Typography>}
          {showPermissions && (
            <label htmlFor="add-user-dialog-permission-all-checkbox">
              <Typography variant="body1" component="div" gutterBottom>
                <Trans>Grant access to:</Trans>
              </Typography>
              <RadioGroup
                name="confirmPermissionSelection"
                value={selectedPermissionsIdx}
                onChange={(_, value) => {
                  setSelectedPermissionsIdx(parseInt(value, 10));
                }}
              >
                {permissionsOptions.map((entry, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={idx}
                    control={<Radio />}
                    label={formatAddPermissionDescription(entry, locale)}
                  />
                ))}
              </RadioGroup>
            </label>
          )}
        </DialogForm>
      )}
    </Formik>
  );
}
