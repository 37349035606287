/* @skip-file-for-translation */
import { t } from '@lingui/core/macro';
import {
  GQTaskWatershedProcessState,
  GQMeasurementProjectStatus,
  GQTaskWatershedProcessStateSimplified,
} from '../generated/graphql';
import assertNever from '@watershed/shared-util/assertNever';
import orderBy from 'lodash/orderBy';

export function getTaskStatusTextSimplified(
  status: GQTaskWatershedProcessStateSimplified,
  approvalsEnabledForProject?: boolean
): string {
  switch (status) {
    case GQTaskWatershedProcessState.Completed:
      return approvalsEnabledForProject
        ? t({
            message: 'Approved',
            context: 'Status label for a task that has been approved',
          })
        : t({
            message: 'Uploaded',
            context: 'Status label for a task that is complete',
          });
    case GQTaskWatershedProcessState.NotStarted:
      return t({
        message: 'Not started',
        context: 'Status label for a task that has not been started',
      });

    case GQTaskWatershedProcessStateSimplified.NeedsApproval:
      return t({
        message: 'Uploaded',
        context:
          'Status label for a task that needs to be approved by someone.',
      });
    case GQTaskWatershedProcessState.InProgress:
      return t({
        message: 'In progress',
        context: 'Status label for a task that is in-progress',
      });

    case GQTaskWatershedProcessStateSimplified.None:
      return t({
        message: 'No status',
        context: 'Status label for a task that has no status',
      });
    default:
      return assertNever(status);
  }
}

const STATUS_ORDER = [
  GQMeasurementProjectStatus.InProgress,
  GQMeasurementProjectStatus.Planned,
  GQMeasurementProjectStatus.Completed,
];
function getIndexForStatus(status: GQMeasurementProjectStatus) {
  return STATUS_ORDER.indexOf(status);
}

export function sortMeasurementProjects<
  T extends {
    status: GQMeasurementProjectStatus;
    completedAt: Date | null;
    coverageEndDate: Date;
    deadline: Date | null;
  },
>(projects: Array<T>): Array<T> {
  return orderBy(
    projects,
    [
      // order by
      (p) => getIndexForStatus(p.status), // Active > Planned > Complete
      (p) => (p.completedAt ? p.coverageEndDate : 0), // if complete, order by coverageEndDate (latest first)
      (p) => (p.completedAt ? 0 : p.deadline), // if incomplete, order by deadline (earliest first)
    ],
    ['asc', 'desc', 'asc']
  );
}

export function getMeasurementProjectLabel(project: {
  name: string;
  completedAt: Date | null;
}): string {
  const projectName = project.name;
  return project.completedAt ? projectName : t`${projectName} (active)`;
}

// This is a little hacky but it's the cleanest way to do this given the current
// API to create projects with datasets/canonical projects without a much larger
// refactor.
export const HISTORICAL_RESULTS_CANONICAL_PROJECT_ID =
  'cancpproj_2ZK4tmCgygps58obCUY3';

// TODO (bryan): Explore whether/how we ought to raise this limit. I'm not
// sure what a good choice is, but 50 feels reasonable? (This is the limit to
// "how many tasks can you bulk-delete in the measurement project screen at
// once"; it's enforced server-side with some nice user-facing messages in the
// client.)
export const USER_UPLOAD_TASK_BULK_DELETE_LIMIT = 50;
