import { Container, Stack, Typography } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import CircleRemoveIcon from '@watershed/icons/components/CircleRemove';
import WarningIcon from '@watershed/icons/components/Warning';
import SyncIcon from '@watershed/icons/components/Sync';
import { maybeNotifySentry } from '@watershed/shared-frontend/utils/errorUtils';
import { useEffect } from 'react';
import PageContainer from '@watershed/shared-frontend/components/PageContainer';
import { ForbiddenPageBody } from '../components/ForbiddenPage';
import { NotFoundPageBody } from '../components/NotFoundPage';
import Button from '@watershed/ui-core/components/Button';
import { useUserContext } from '../utils/UserContext';
import { UnexpectedErrorState } from '@watershed/ui-core/components/UnexpectedErrorState';
import TextLink from '@watershed/ui-core/components/TextLink';
import {
  SUPPORT_EMAIL_ADDRESS,
  SUPPORT_EMAIL_LINK,
} from '@watershed/shared-universal/dashboardRoutes';
import frontendHoneycomb from '@watershed/shared-frontend/utils/frontendHoneycomb';
import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';
import useEffectOnce from '@watershed/shared-frontend/hooks/useEffectOnce';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export interface FlexiError extends Error {
  code?: string;
}
function UnexpectedErrorPageBody({ error }: { error: FlexiError }) {
  useEffect(() => {
    maybeNotifySentry(error);
  }, [error]);
  return <UnexpectedErrorState />;
}

function CrossOrgErrorPageBody({ error }: { error: FlexiError }) {
  const user = useUserContext();

  useEffect(() => {
    if (
      !user.loginAsUser &&
      !user.userIsWatershedContractor &&
      !user.userIsWatershedEmployee
    )
      maybeNotifySentry(error);
  }, [error, user]);

  return (
    <>
      <CircleRemoveIcon size={128} marginBottom={1} color="warning.main" />
      <Typography variant="h1" gutterBottom>
        <Trans context="status">
          Cannot load across orgs while authenticated
        </Trans>
      </Typography>

      <Typography variant="body2">
        <Trans context="referring to a browser tab that has been open too long">
          Is this a stale tab?
        </Trans>
      </Typography>
    </>
  );
}

function LogoutErrorPageBody() {
  return (
    <>
      <WarningIcon size={128} marginBottom={1} color="secondary.main" />
      <Typography variant="h1" gutterBottom>
        <Trans>Your login session has expired</Trans>
      </Typography>

      <Typography variant="body2">
        <Trans context="status">Redirecting you to login…</Trans>
      </Typography>
    </>
  );
}

function GraphqlSchemaErrorPageBody({ error }: { error: FlexiError }) {
  useEffect(() => {
    maybeNotifySentry(error);
  }, [error]);

  return (
    <>
      <SyncIcon size={128} marginBottom={3} color="warning.main" />
      <Typography variant="h1" gutterBottom sx={{ lineHeight: 1.2 }}>
        <Trans>
          Uh oh! Our application has gotten out of sync with your web browser.
        </Trans>
      </Typography>
      <Typography variant="body2" sx={{ fontSize: 17 }} paragraph>
        <Trans>Please reload the page to fix the issue.</Trans>
      </Typography>
      <Button startIcon={<SyncIcon />} onClick={() => window.location.reload()}>
        <Trans context="button copy">Reload this page</Trans>
      </Button>
    </>
  );
}

function DataResponseTooLargeErrorBody({ error }: { error: FlexiError }) {
  return (
    <>
      <WarningIcon size={128} marginBottom={1} color="error.main" />
      <Typography variant="h1" gutterBottom>
        <Trans context="oh no too big!">
          The size of the data requested is too large
        </Trans>
      </Typography>
      <Typography variant="body2">
        <Trans context="support link">
          Try narrowing your query or contact support for assistance:{' '}
          <TextLink href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL_ADDRESS}</TextLink>
        </Trans>
      </Typography>
    </>
  );
}

/**
 * Internationalized strings must be rendered within an I18nProvider. However,
 * there are certain cases under which we cannot render these strings, e.g.
 * - Errors thrown outside of the I18nProvider
 * - Errors loading the translations for internationalized strings, i.e. MessageLoadingError
 * For these cases, we'll render an uninternationalized error page.
 */
function UninternationalizedErrorBody({ error }: { error: FlexiError }) {
  useEffect(() => {
    maybeNotifySentry(error);
  }, [error]);

  return (
    <>
      <WarningIcon size={128} marginBottom={1} color="error.main" />
      <Typography variant="h1" gutterBottom>
        {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready */}
        Something went wrong
      </Typography>
      <Typography variant="body2">
        {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready */}
        Our engineering team has been notified and will investigate the issue.
        Reloading the page or hitting the back button in your browser may
        resolve the issue. If you have additional questions, please email{' '}
        <TextLink href="mailto:support@watershed.com">
          {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready */}
          support@watershed.com
        </TextLink>
      </Typography>
    </>
  );
}

/**
 * Map of error codes to custom error pages. If an error does not have a code
 * with a custom page, the fallback "Unexpected error" is rendered.
 */
const errorPageBodyForCode: Record<
  string,
  (...args: Array<any>) => JSX.Element
> = {
  CROSS_ORG: CrossOrgErrorPageBody,
  UNAUTHENTICATED: LogoutErrorPageBody,
  FORBIDDEN: ForbiddenPageBody,
  NOT_FOUND: NotFoundPageBody,
  GRAPHQL_VALIDATION_FAILED: GraphqlSchemaErrorPageBody,
  DATA_RESPONSE_TOO_LARGE: DataResponseTooLargeErrorBody,
  MESSAGE_LOADING_ERROR: UninternationalizedErrorBody,
};

function getErrorPageForCode(error: FlexiError): JSX.Element {
  const ErrorPageBody =
    (error.code ? errorPageBodyForCode[error.code] : null) ??
    UnexpectedErrorPageBody;
  return <ErrorPageBody error={error} />;
}

async function sendErrorEventToHoneycomb(error?: FlexiError) {
  await frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_USER_VISIBLE_ERROR_PAGE,
    [CustomSpanAttributes.BROWSER_USER_VISIBLE_ERROR_CODE]:
      error?.code ?? 'GENERIC',
  });
}

export default function ErrorPage({ error }: { error: FlexiError }) {
  useEffect(() => {
    console.error(error);
    Analytics.error('errorPageShown', { code: error.code });
    void sendErrorEventToHoneycomb(error);
  }, [error]);

  return (
    <PageContainer
      isFullPage={false}
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 64,
        paddingBottom: 64,
      }}
      testId={TestIds.ErrorStateFullPage}
    >
      {getErrorPageForCode(error)}
    </PageContainer>
  );
}

export function GenericUnexpectedErrorPage() {
  useEffectOnce(() => {
    void sendErrorEventToHoneycomb();
  });
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBlock: 64,
        paddingInline: 32,
        width: '100vw',
        height: '100vh',
      }}
    >
      <Stack
        maxWidth="sm"
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <UnexpectedErrorState />
      </Stack>
    </Container>
  );
}
