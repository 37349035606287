import {
  getFromStorage,
  setStorage,
} from '@watershed/shared-frontend/hooks/useStorageState';
import { WIN_FINANCE_PREFIX } from '@watershed/shared-universal/dashboardRoutes';
import {
  createContext,
  SetStateAction,
  Dispatch,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

/* 
  "Product" in this case is specifically the set of options in the logo bar 
  in the navigation. You are either in one product or another and switch from
  the Nav.
*/
export enum WatershedProduct {
  Corporate = 'Corporate',
  Finance = 'Finance',
  Unknown = 'Unknown',
}

const defaultContextValue: {
  currentProduct: WatershedProduct;
  setCurrentProduct: Dispatch<SetStateAction<WatershedProduct>>;
} = {
  currentProduct: WatershedProduct.Unknown,
  setCurrentProduct: (setStateAction: SetStateAction<WatershedProduct>) => {
    const product =
      typeof setStateAction === 'function'
        ? setStateAction(defaultContextValue.currentProduct)
        : setStateAction;
    throw new Error(
      `setCurrentProduct(${product}) called with no parent context provider`
    );
  },
};
export const CurrentProductContext = createContext(defaultContextValue);

export function useCurrentProductContext() {
  return useContext(CurrentProductContext);
}

export const useIsOnCorporatePage = () => {
  const { currentProduct } = useCurrentProductContext();
  return currentProduct === WatershedProduct.Corporate;
};

export const useIsOnFinancePage = () => {
  const { currentProduct } = useCurrentProductContext();
  return currentProduct === WatershedProduct.Finance;
};

export const useIsOnUnknownPage = () => {
  const { currentProduct } = useCurrentProductContext();
  return currentProduct === WatershedProduct.Unknown;
};

// HACK(riley): There are a handful of routes that are shared between products,
// so it’s impossible to determine the current product based on the URL alone.
// Longer term, this seems like a bad pattern. To make things better in the
// short term, I’m storing the most recent product so we can at least get the
// right product when the page is refreshed.
const PRODUCT_STORAGE_KEY = 'currentProduct';
const sharedProductRoutes = [/^\/reports\//, /^\/benchmarks\//];

export function determineCurrentProduct(
  pathname: string,
  canAccessCorporate: boolean
) {
  if (!canAccessCorporate) {
    return WatershedProduct.Finance;
  }
  if (pathname.startsWith(WIN_FINANCE_PREFIX)) {
    return WatershedProduct.Finance;
  }
  if (sharedProductRoutes.some((regex) => regex.test(pathname))) {
    return getFromStorage(
      PRODUCT_STORAGE_KEY,
      WatershedProduct.Corporate,
      localStorage
    );
  }
  return WatershedProduct.Corporate;
}

export function CurrentProductProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentProduct, setCurrentProduct] = useState(
    defaultContextValue.currentProduct
  );

  // Mirror the current product to localStorage so we can restore it after a
  // page reload, for pages where we can’t infer product from the URL.
  useEffect(() => {
    if (currentProduct !== WatershedProduct.Unknown) {
      setStorage(PRODUCT_STORAGE_KEY, currentProduct, localStorage);
    }
  }, [currentProduct]);

  const providerValue = useMemo(
    () => ({ currentProduct, setCurrentProduct }),
    [currentProduct, setCurrentProduct]
  );

  return (
    <CurrentProductContext.Provider value={providerValue}>
      {children}
    </CurrentProductContext.Provider>
  );
}
